import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import SettingsIcon from '@material-ui/icons/Settings';
import { styled } from '@material-ui/styles';
import Button from '../inputs/Button';
import ManageTags from './ManageTags';
import { withTranslation } from 'react-i18next';

const styles = theme => ({
  chipTag: {
    height: 24,
    backgroundColor: '#f7f8f9',
    padding: '0 8px ',
    marginRight: 8,
    marginTop: 8,
    boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.15)',
  },
  textModeColors: {
    color: theme.modeColors.textColor,
  },
});

const StyledH5 = styled('h5')(({ theme }) => ({
  color: theme.modeColors.singleProposalSideNavText,
}));

class Tags extends Component {
  state = {
    isModalOpen: false,
  };

  openModal = () => this.setState({ isModalOpen: true });

  closeModal = () => this.setState({ isModalOpen: false });

  render() {
    const {
      title,
      classes,
      edit,
      onCreate,
      tags,
      proposalTags,
      removeTag,
      removeTagFromProposal,
      addTagToProposal,
      proposal,
      t,
    } = this.props;
    return (
      <div>
        <div className="d-flex align-items-center justify-content-between mb-1">
          <StyledH5 className="mb-0">{title}</StyledH5>
          {edit && (
            <Button style={{ padding: '5px' }} onClick={this.openModal} size="sm">
              <span className={`d-flex align-items-center ${classes.textModeColors}`}>
                <span className="mr-1">{t('Manage')} </span>
                <SettingsIcon fontSize="small" />
              </span>
            </Button>
          )}
        </div>

        <div>
          {proposalTags
            ? proposalTags.map(tag => (
                <Chip
                  key={tag.id}
                  label={tag.name}
                  classes={{
                    root: classes.chipTag,
                    label: 'xs p-0',
                  }}
                />
              ))
            : null}
          {!edit && !proposalTags.length && (
            <div className="sm text-gray"> {t('The secretary will add public tags')}</div>
          )}
          {edit && (
            <Chip
              onClick={this.openModal}
              label={t('Add tag +')}
              classes={{
                root: classes.chipTag,
                label: 'xs p-0',
              }}
            />
          )}
        </div>
        <ManageTags
          removeTagFromProposal={removeTagFromProposal}
          tags={tags}
          proposalTags={proposalTags}
          onCreate={onCreate}
          removeTag={removeTag}
          addTagToProposal={addTagToProposal}
          close={this.closeModal}
          isOpen={this.state.isModalOpen}
          proposal={proposal}
        />
      </div>
    );
  }
}

export default withTranslation('proposals')(withStyles(styles)(Tags));
