import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationEN from './locales/en/translation.json';
import navbarEN from './locales/en/navbar.json';
import proposalsEN from './locales/en/proposals.json';
import translationFI from './locales/fi/translation.json';
import navbarFI from './locales/fi/navbar.json';
import proposalsFI from './locales/fi/proposals.json';

const resources = {
  en: {
    translation: translationEN,
    navbar: navbarEN,
    proposals: proposalsEN,
  },
  fi: {
    translation: translationFI,
    navbar: navbarFI,
    proposals: proposalsFI,
  },
};

const getSelectedLanguage = () => {
  const valueInLocalStorage = window.localStorage.getItem('selectedLanguage');
  if (valueInLocalStorage) {
    return JSON.parse(valueInLocalStorage);
  }
  return 'fi';
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: getSelectedLanguage(),
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
