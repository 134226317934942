import React from 'react'
import styled from 'styled-components'

export default function Input({
  inputId,
  label,
  inputName,
  inputType,
  handleSave,
  inputValue
}) {
  const [value, setValue] = React.useState(inputValue)

  const handleChangeText = event => {
    const { value } = event.target
    setValue(value)
  }

  React.useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (value) {
        handleSave(value)
      }
    }, 3000)

    return () => clearTimeout(delayDebounceFn)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  if (!label) {
    return (
      <StyledInput
        type={inputType}
        id={inputId}
        name={inputName}
        value={value}
        onChange={handleChangeText}
      />
    )
  }

  return (
    <Label htmlFor={inputId}>
      <span style={{ minWidth: 150 }}>{label}</span>
      <StyledInput
        type={inputType}
        id={inputId}
        name={inputName}
        value={value}
        onChange={handleChangeText}
      />
    </Label>
  )
}

const Label = styled.label`
  display: flex;
  align-items: center;
  gap: 24px;
`

const StyledInput = styled.input`
  border: 1px solid #f1f1f4;
  padding: 12px;
  font-size: 1rem;
  width: 100%;
`
