import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { firestoreConnect, isLoaded } from 'react-redux-firebase';
import { Redirect, withRouter } from 'react-router-dom';
import SetPassword from '../set-password';
// import Spinner from '../spinner/Spinner';

const PrivateComponent = ({ component: C, auth, users, profile, ...props }) => {
  if (auth.uid) {
    if (!isLoaded(users) || !profile.isLoaded) {
      return <div />;
    }
    const users_id = users.map(user => user.id);

    if (users_id.includes(auth.uid)) {
      if (profile && profile.newUser) {
        return <SetPassword />;
      }
      return <C {...props} />;
    }
    return <div>user not Allowed</div>;
  }
  return <Redirect to="/login" />;
};

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    users: state.firestore.ordered.users,
  };
};

export default withRouter(
  compose(
    connect(mapStateToProps),
    firestoreConnect([{ collection: 'users' }])
  )(PrivateComponent)
);
