const initState = {
  authError: null,
};

const authReducer = (state = initState, action) => {
  switch (action.type) {
    case 'LOGIN_ERROR':
      return {
        ...state,
        authError: true,
      };
    case 'LOGIN_SUCCESS':
      return {
        ...state,
        authError: null,
      };
    case 'SIGNOUT_SUCCESS':
      // console.log('signout success');
      return state;
    case 'RESET_AUTH_ERROR':
      return {
        ...state,
        authError: null,
      };
    default:
      return state;
  }
};

export default authReducer;
