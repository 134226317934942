import qs from 'querystringify';
import _ from 'lodash';

export const getSearchQuery = search => {
  return qs.parse(search);
};

export const updateSearchQuery = (search, query, val) => {
  val = val ? val : null;
  if (!val || val.length === 0) {
    const searchQuery = qs.parse(search);
    delete searchQuery[query];
    return qs.stringify({ ...searchQuery }, true);
  } else {
    if (query === 'search') {
      return qs.stringify({ [query]: val }, true);
    } else {
      const searchQuery = qs.parse(search);
      delete searchQuery['search'];
      return qs.stringify({ ...searchQuery, [query]: val }, true);
    }
  }
};

const findProposalByNumber = (searchKey, initData) => {
  const proposalNumber = Number(searchKey.trim());
  return _.pickBy(initData, (val, key) => proposalNumber === val.number);
};

const findElements = (searchKey, initData) => {
  let elements = {};

  _.pickBy(initData, (val, key) => {
    return _.pickBy(val.elements, (element, elementKey) => {
      if (element.name.toLowerCase().includes(searchKey.trim())) {
        // try to match structure of proposal passed to openSingleElement in proposals/index.js
        element.proposal = [];
        element.proposal[0] = key;
        element.proposal[1] = val;
        elements = {
          ...elements,
          [elementKey]: element,
        };
      }
      return elements;
    });
  });

  return elements;
};

export const searchData = (search, initData) => {
  if (search.includes(',') || search.includes(' ')) {
    const searchKeys = search.includes(',') ? search.split(',') : search.split(' ');
    const proposals = _.reduce(
      searchKeys,
      (results, searchKey) => {
        const foundProposal = findProposalByNumber(searchKey, initData);
        return Object.assign(results, foundProposal);
      },
      {}
    );
    const elements = _.reduce(
      searchKeys,
      (results, searchKey) => {
        const foundElements = findElements(searchKey, initData);
        return Object.assign(results, foundElements);
      },
      {}
    );

    return { proposals, elements };
  }

  // Search in a range, i.e 5 - 10
  if (search.includes('-')) {
    const searchArrang = search.split('-'),
      firstNumber = parseInt(searchArrang[0].replace(' ', ''), 10),
      secondNumber = parseInt(searchArrang[1].replace(' ', ''), 10),
      biggerNumber = firstNumber > secondNumber ? firstNumber : secondNumber,
      smallNumber = firstNumber < secondNumber ? firstNumber : secondNumber;
    const proposals = _.pickBy(
      initData,
      (val, key) => val.number >= smallNumber && val.number <= biggerNumber
    );
    const allElements = Object.assign({}, ..._.map(initData, val => val.elements));
    const elements = _.pickBy(
      allElements,
      (val, key) => val.number >= smallNumber && val.number <= biggerNumber
    );
    return { proposals, elements };
  }

  search = search.toLowerCase();
  const proposals = _.pickBy(
    initData,
    (val, key) =>
      _.includes(val.name.toLowerCase(), search) ||
      _.includes(val.number.toString(), search)
  );

  // Added proposal ID and value to use for search elements
  const elements = findElements(search, initData);

  return { proposals, elements };
};
