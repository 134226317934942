import * as React from 'react';
import { connect } from 'react-redux';
import { addToDataBase, deleteFromDataBase } from '../../redux/actions/dataBase';
import { makeStyles, MenuItem, Select } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  hideIconPadding: {
    '& .MuiSelect-select': {
      paddingRight: 10,
    },
  },
}));

function CommentSelect({ comment, addToDataBase, deleteFromDataBase }) {
  // const [option, setOption] = React.useState('');
  const classes = useStyles();
  const { t } = useTranslation('proposals');

  const handleChangeOption = event => {
    const { value } = event.target;
    // setOption(event.target.value);

    if (value === 'delete') {
      addToDataBase('deletedComments', comment?.commentId, comment)
        .then(() => console.log('success'))
        .catch(error => console.log(error));
      deleteFromDataBase('comments', comment?.commentId)
        .then(() => console.log('success'))
        .catch(error => console.log(error));
    }
    return;
  };

  return (
    <Select
      className={classes.hideIconPadding}
      value=""
      disableUnderline
      IconComponent={() => (
        <MoreVert
          style={{
            height: 18,
            cursor: 'pointer',
            position: 'absolute',
            right: '0',
            display: 'inline-block',
            color: '#545454',
            pointerEvents: 'none',
          }}
        />
      )}
      onChange={handleChangeOption}
    >
      <MenuItem value="delete">{t('Delete')}</MenuItem>
    </Select>
  );
}

const mapDispatchToProps = dispatch => {
  return {
    addToDataBase: (coll, doc, data) => dispatch(addToDataBase(coll, doc, data)),
    deleteFromDataBase: (coll, doc) => dispatch(deleteFromDataBase(coll, doc)),
  };
};

export default connect(null, mapDispatchToProps)(CommentSelect);
