import * as React from 'react';
import { connect } from 'react-redux';
import { makeStyles, MenuItem, Select } from '@material-ui/core';
import LanguageIcon from '@material-ui/icons/Language';

import { addToDataBase } from '../redux/actions/dataBase';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  hideIconPadding: {
    padding: 4,
    '& .MuiSelect-select': {
      paddingRight: 10,
    },
  },
  selected: {
    backgroundColor: '#8790c3 !important',
    color: 'white',
    fontWeight: 600,
  },
}));

function SelectLanguage({ profile, userId, addToDataBase }) {
  const initialLangValue = profile?.selectedLanguage || 'fi';
  const [option, setOption] = React.useState(initialLangValue);
  const { i18n } = useTranslation();
  const classes = useStyles();

  const handleChangeOption = event => {
    const { value } = event.target;
    setOption(value);
    i18n.changeLanguage(value);

    addToDataBase('users', userId, { selectedLanguage: value })
      .then(() => console.log('success'))
      .catch(err => console.log(err));
    return;
  };

  return (
    <Select
      className={classes.hideIconPadding}
      value=""
      disableUnderline
      IconComponent={() => (
        <LanguageIcon
          style={{
            height: 25,
            cursor: 'pointer',
            position: 'absolute',
            right: '0',
            display: 'inline-block',
            color: '#545454',
            pointerEvents: 'none',
          }}
        />
      )}
      onChange={handleChangeOption}
    >
      <MenuItem value="fi" className={`${option === 'fi' ? classes.selected : ''}`}>
        Suomi
      </MenuItem>
      <MenuItem value="en" className={`${option === 'en' ? classes.selected : ''}`}>
        English
      </MenuItem>
    </Select>
  );
}

const mapStateToProps = state => {
  return {
    profile: state.firebase.profile,
    userId: state.firebase.auth.uid,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addToDataBase: (coll, doc, data) => dispatch(addToDataBase(coll, doc, data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectLanguage);
