import React from 'react';
import styled from 'styled-components';
import Skeleton from '@material-ui/lab/Skeleton';
import Box from '@material-ui/core/Box';

const FilterSection = styled.div`
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
  padding-bottom: 2rem;
`;

const ProposalsSection = styled.div`
  margin-top: 3rem;
`;

export default function LoadingProposals() {
  return (
    <div>
      <FilterSection>
        <Box display="flex" alignItems="center" mt={3} px={4}>
          {Array.from(new Array(4)).map((element, index) => (
            <Box key={index} sx={{ marginRight: '20px' }}>
              <Skeleton variant="rect" width="330px" height="30px" />
            </Box>
          ))}

          <Skeleton
            variant="rect"
            width="20px"
            height="20px"
            style={{ marginRight: '10px' }}
          />
          <Skeleton variant="text" width="100px" />
        </Box>

        <Box mt={2} px={4}>
          <Skeleton variant="text" width="200px" />
        </Box>
      </FilterSection>

      <ProposalsSection>
        <Box display="flex" alignItems="center" justifyContent="space-between" px={4}>
          <Skeleton variant="text" width="200px" />

          <Box display="flex" alignItems="center">
            <Box mr={2}>
              <Skeleton variant="rect" width="100px" height="30px" />
            </Box>
            <Box mr={1}>
              <Skeleton variant="text" width="50px" />
            </Box>
            <Box mr={2}>
              <Skeleton variant="rect" width="200px" height="30px" />
            </Box>
            <Skeleton variant="rect" width="100px" height="30px" />
          </Box>
        </Box>

        <Box display="flex" alignItems="center" flexWrap="wrap" mt={6} px={4}>
          {Array.from(new Array(73)).map((element, index) => (
            <Box key={index} sx={{ marginRight: '25px', marginBottom: '25px' }}>
              <Skeleton variant="rect" width="184px" height="140px" />
            </Box>
          ))}
        </Box>
      </ProposalsSection>
    </div>
  );
}
