import { Button } from '@material-ui/core';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { LINKS, PHASE_2_LINKS } from '../util/3d-model-links';

function ThreeDModel({ proposalId, proposals, selectedPhase }) {
  const iframeRef = React.useRef();
  const links = selectedPhase === 'phase-2' ? PHASE_2_LINKS : LINKS;

  const focusIframe = () => {
    // console.log('FOCUS');
    iframeRef.current.focus();
  };

  const proposalNumber = _.find(proposals, (proposal, key) => key === proposalId).number;
  const hasLink = !!links[proposalNumber];

  if (!hasLink) {
    return <div>Missing 3D model</div>;
  }

  return (
    <div className="mt-3">
      <Button
        size="large"
        variant="contained"
        onClick={focusIframe}
        color="primary"
        style={{ position: 'absolute', top: '155px' }}
      >
        Enable model keyboard shortcuts
      </Button>
      <iframe ref={iframeRef} src={links[proposalNumber]} title={proposalNumber} />
    </div>
  );
}

const mapStateToProps = state => {
  return {
    proposals: state.proposals.data,
    reactAppUrl: state.proposals.envValues.reactAppUrl,
    selectedPhase: state.proposals.selectedPhase,
  };
};

export default connect(mapStateToProps, null)(ThreeDModel);
