import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  lg: {
    borderRadius: 0,
    fontFamily: 'HarmoniaSansStd',
    lineHeight: '1.5',
    fontSize: '1rem',
    fontWeight: '700',
    padding: '8px 20px',
    textTransform: 'unset',
    margin: 0,
    boxShadow: 'none',
  },
  md: {
    borderRadius: 0,
    fontFamily: 'HarmoniaSansStd',
    lineHeight: '1.2',
    fontSize: '0.875rem',
    fontWeight: '700',
    padding: '8px 12px 6px',
    textTransform: 'unset',
    margin: 0,
    boxShadow: 'none',
  },
  sm: {
    borderRadius: 0,
    fontFamily: 'HarmoniaSansStd',
    lineHeight: '1.2',
    fontSize: '0.75rem',
    fontWeight: '700',
    padding: '4px 8px',
    textTransform: 'unset',
    margin: 0,
    boxShadow: 'none',
  },
  xs: {
    minWidth: 34,
    borderRadius: 0,
    fontFamily: 'HarmoniaSansStd',
    lineHeight: '1.2',
    fontSize: '0.75rem',
    fontWeight: '700',
    padding: '4px',
    textTransform: 'unset',
    margin: 0,
    boxShadow: 'none',
  },
});

function CustomButton(props) {
  const { size, children, classes, ...other } = props;
  return (
    <Button className={classes[size]} {...other}>
      {children || 'Button'}
    </Button>
  );
}

CustomButton.propTypes = {
  size: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
};

export default withStyles(styles)(CustomButton);
