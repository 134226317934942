import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

import useFirestore from './hooks/useFirestore'
import useCollection from './hooks/useCollection'

export default function CommentsDeadline() {
  const [date, setDate] = React.useState('')
  const { documents } = useCollection('info')
  const { updateDocument } = useFirestore('info')
  const { t } = useTranslation('proposals')

  const handleChange = date => {
    setDate(date)
    updateDocument('general', { commentsDeadline: date })
  }

  React.useEffect(() => {
    if (documents && !date) {
      const formattedDate =
        documents && !!documents?.general?.commentsDeadline
          ? documents?.general?.commentsDeadline.toDate()
          : ''
      setDate(formattedDate)
    }
  }, [date, documents])

  return (
    <Label htmlFor="comments-close-date">
      <span style={{ minWidth: 150, marginRight: 24 }}>
        {t('Comments deadline')}:
      </span>

      <DatePicker
        wrapperClassName="date-picker"
        selected={date}
        onChange={date => handleChange(date)}
        showTimeSelect
        dateFormat="dd MMMM, yyyy - h:mm aa"
      />
    </Label>
  )
}

const Label = styled.label`
  display: flex;
  align-items: center;

  .react-datepicker__input-container input {
    border: 1px solid #f1f1f4;
    padding: 12px;
    font-size: 1rem;
    min-width: 300px;
  }
`
