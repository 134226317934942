import React from 'react';
import styled from 'styled-components';

export default function Button({ type, children, onClick, variant }) {
  return (
    <StyledButton
      type={type === 'submit' ? 'submit' : 'button'}
      onClick={onClick}
      variant={variant}
    >
      {children}
    </StyledButton>
  );
}

const variants = {
  primary: {
    backgroundColor: 'hsl(231, 48%, 48%)',
    color: '#fff',
    '&:hover': {
      backgroundColor: 'hsl(231, 48%, 55%)',
    },
  },
  secondary: {
    color: 'hsl(231, 48%, 48%)',
    '&:hover': {
      backgroundColor: '#d4dbff',
    },
  },
  underlined: {
    borderBottom: '1px solid hsl(231, 48%, 48%)',
    padding: 0,
    color: 'hsl(231, 48%, 48%)',
    '&:hover': {
      borderBottom: '1px solid var(--indigo-darken-10)',
      color: 'var(--indigo-darken-10)',
    },
  },
  outlined: {
    borderColor: 'hsl(231, 48%, 48%)',
    color: 'hsl(231, 48%, 48%)',
    '&:hover': {
      backgroundColor: 'var(--gray)',
    },
  },
};

const StyledButton = styled.button`
  border: 1px solid transparent;
  font-size: 1rem;
  padding: 12px 24px;
  background-color: transparent;
  text-transform: none;
  cursor: pointer;
  transition: all 0.2s ease;
  ${({ variant }) => variant && variants[variant]}
`;
