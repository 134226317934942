import React from 'react'
import styled from 'styled-components'

const options = [
  { value: 'number', label: 'Type - Number' },
  { value: 'text', label: 'Type - Text' },
  { value: 'switch', label: 'Type - Switch' }
]

export default function SelectOptions({ onChange, selectedType }) {
  return (
    <Select
      name="types"
      id="input-types"
      value={selectedType}
      onChange={onChange}
    >
      <option value="">-- Please choose input type --</option>
      {options.map(({ value, label }) => (
        <option key={value} value={value}>
          {label}
        </option>
      ))}
    </Select>
  )
}

const Select = styled.select`
  border: 0;
  padding: 10px;
  font-size: 0.9rem;
  min-width: 240px;
`
