import React, { useState, useEffect } from 'react'
import { compose } from 'redux'
import { firestoreConnect } from 'react-redux-firebase'
import { connect } from 'react-redux'
import { withSnackbar } from 'notistack'
import { EditorState, ContentState, convertToRaw } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'

import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { withStyles } from '@material-ui/core/styles'

import { addToDataBase } from '../redux/actions/dataBase'
import Button from '../inputs/Button'
import SingleInfoTab from './SingleInfoTab'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { useTranslation } from 'react-i18next'
import useCollection from './submission/hooks/useCollection'

const styles = theme => ({
  root: {
    padding: '32px',
    margin: 0,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto'
  },
  tab: {
    fontSize: '1rem',
    fontFamily: 'HarmoniaSansStd !important',
    padding: 0,
    textAlign: 'left',
    minWidth: 270,
    minHeight: 32,
    marginBottom: 8,
    backgroundColor: theme.modeColors.tabBackground
  },
  tabLabel: {
    padding: '4px 16px',
    alignItems: 'start'
  },
  selected: {
    backgroundColor: theme.modeColors.selectedTabBackground,
    fontWeight: 700
  },
  tabContainer: {
    marginLeft: 16,
    padding: 32,
    backgroundColor: theme.modeColors.navBackground,
    flex: 1,
    height: '100%'
  },
  textModeColors: {
    color: theme.modeColors.proposalTitle
  },
  extraTabContainer: {
    flex: 1,
    backgroundColor: theme.modeColors.navBackground,
    // backgroundColor: 'lightgreen',
    display: 'flex',
    flexDirection: 'column'
  }
})

const VerticalTabs = withStyles(theme => ({
  flexContainer: {
    flexDirection: 'column'
  },
  indicator: {
    display: 'none'
  }
}))(Tabs)

function CompetitionInfo(props) {
  const { documents } = useCollection(
    'TabsWithIframeLinks',
    'availableAt',
    'info'
  )
  const { t } = useTranslation('proposals')
  const { classes, profile, generalInfo } = props
  const [activeIndex, setActiveIndex] = useState(0)
  const [editCompetitionInfo, setEditCompetitionInfo] = useState(false)
  const [editorDetails, setEditorDetails] = useState(EditorState.createEmpty())
  const [editorMaterials, setEditorMaterials] = useState(
    EditorState.createEmpty()
  )
  const [editorMaterialsJury, setEditorMaterialsJury] = useState(
    EditorState.createEmpty()
  )
  const [editorRules, setEditorRules] = useState(EditorState.createEmpty())
  const [judgingDocs, setJudgingDocs] = useState(EditorState.createEmpty())
  const [userManual, setUserManual] = useState(EditorState.createEmpty())

  const handleChange = (_, activeIndex) => setActiveIndex(activeIndex)

  const onEditorDetailsChange = editorState => setEditorDetails(editorState)
  const onEditorMaterialsChange = editorState => setEditorMaterials(editorState)
  const onEditorMaterialsJuryChange = editorState =>
    setEditorMaterialsJury(editorState)
  const onEditorRulesChange = editorState => setEditorRules(editorState)
  const onJudgingDocsChange = editorState => setJudgingDocs(editorState)
  const onUserManualChange = editorState => setUserManual(editorState)

  const editCompetition = () => setEditCompetitionInfo(true)
  const closeEditCompetition = () => setEditCompetitionInfo(false)

  const createInfoTabContent = tabHtmlData => {
    let result = null

    if (!!tabHtmlData) {
      const contentBlockDetails = htmlToDraft(tabHtmlData)
      const contentStateDetails = ContentState.createFromBlockArray(
        contentBlockDetails.contentBlocks
      )
      result = EditorState.createWithContent(contentStateDetails)
      return result
    }

    result = EditorState.createEmpty()
    return result
  }

  useEffect(() => {
    const {
      htmlDetails,
      htmlRules,
      htmlMaterials,
      htmlMaterialsJury,
      htmlJudgingDocs,
      htmlUserManual
    } = generalInfo
    // Tab - Competition details
    const editorDetails = createInfoTabContent(htmlDetails)
    setEditorDetails(editorDetails)
    // Tab - Competition rules
    const editorRules = createInfoTabContent(htmlRules)
    setEditorRules(editorRules)
    // Tab - Material for competitors
    const editorMaterials = createInfoTabContent(htmlMaterials)
    setEditorMaterials(editorMaterials)
    // Tab - Material for jury
    const editorMaterialsJury = createInfoTabContent(htmlMaterialsJury)
    setEditorMaterialsJury(editorMaterialsJury)
    // Tab - Judging documents
    const judgingDocs = createInfoTabContent(htmlJudgingDocs)
    setJudgingDocs(judgingDocs)
    // Tab - User manual
    const userManual = createInfoTabContent(htmlUserManual)
    setUserManual(userManual)
  }, [generalInfo])

  const saveEditCompetition = () => {
    const { addToDataBase, enqueueSnackbar } = props
    const htmlDetails = draftToHtml(
      convertToRaw(editorDetails.getCurrentContent())
    )
    const htmlRules = draftToHtml(convertToRaw(editorRules.getCurrentContent()))
    const htmlMaterials = draftToHtml(
      convertToRaw(editorMaterials.getCurrentContent())
    )
    const htmlMaterialsJury = draftToHtml(
      convertToRaw(editorMaterialsJury.getCurrentContent())
    )
    const htmlJudgingDocs = draftToHtml(
      convertToRaw(judgingDocs.getCurrentContent())
    )
    const htmlUserManual = draftToHtml(
      convertToRaw(userManual.getCurrentContent())
    )
    const data = {
      htmlDetails,
      htmlRules,
      htmlMaterials,
      htmlMaterialsJury,
      htmlJudgingDocs,
      htmlUserManual
    }

    addToDataBase('generalInfo', 'id-for-doc', data)
      .then(() => {
        enqueueSnackbar('Edit competition information successfully', {
          variant: 'success'
        })
        closeEditCompetition()
      })
      .catch(err => {
        enqueueSnackbar(err.message, { variant: 'error' })
        console.error(err.message)
      })
  }

  const createMarkup = data => {
    return { __html: data }
  }

  const INFO_TABS = [
    t('Competition details'),
    t('Competition rules'),
    t('Materials for competitors'),
    t('Materials for jury'),
    t('Judging document'),
    t('User manual')
  ]

  const INFO_TABS_CONTENT = [
    {
      editCompetitionInfo: editCompetitionInfo,
      generalInfo: generalInfo,
      editorState: editorDetails,
      changeEditorState: onEditorDetailsChange,
      createMarkup: createMarkup(generalInfo.htmlDetails),
      tabHeader: t('Competition details')
    },
    {
      editCompetitionInfo: editCompetitionInfo,
      generalInfo: generalInfo,
      editorState: editorRules,
      changeEditorState: onEditorRulesChange,
      createMarkup: createMarkup(generalInfo.htmlRules),
      tabHeader: t('Competition rules')
    },
    {
      editCompetitionInfo: editCompetitionInfo,
      generalInfo: generalInfo,
      editorState: editorMaterials,
      changeEditorState: onEditorMaterialsChange,
      createMarkup: createMarkup(generalInfo.htmlMaterials),
      tabHeader: t('Materials for competitors')
    },
    {
      editCompetitionInfo: editCompetitionInfo,
      generalInfo: generalInfo,
      editorState: editorMaterialsJury,
      changeEditorState: onEditorMaterialsJuryChange,
      createMarkup: createMarkup(generalInfo.htmlMaterialsJury),
      tabHeader: t('Materials for jury')
    },
    {
      editCompetitionInfo: editCompetitionInfo,
      generalInfo: generalInfo,
      editorState: judgingDocs,
      changeEditorState: onJudgingDocsChange,
      createMarkup: createMarkup(generalInfo.htmlJudgingDocs),
      tabHeader: t('Judging document')
    },
    {
      editCompetitionInfo: editCompetitionInfo,
      generalInfo: generalInfo,
      editorState: userManual,
      changeEditorState: onUserManualChange,
      createMarkup: createMarkup(generalInfo.htmlUserManual),
      tabHeader: t('User manual')
    }
  ]

  return (
    <div className={classes.root}>
      <h1 className={`mb-4 ${classes.textModeColors}`}>
        {t('Competition information')}{' '}
      </h1>
      <div
        style={{
          display: 'flex',
          gap: '10px',
          flex: 1,
          marginBottom: 20
        }}
      >
        <VerticalTabs value={activeIndex} onChange={handleChange}>
          {INFO_TABS.map(tabLabel => (
            <Tab
              key={tabLabel}
              label={tabLabel}
              classes={{
                root: classes.tab,
                selected: classes.selected,
                wrapper: classes.tabLabel
              }}
            />
          ))}
          {!!documents
            ? Object.values(documents).map(extraTab => (
                <Tab
                  key={extraTab.id}
                  label={extraTab.label}
                  classes={{
                    root: classes.tab,
                    selected: classes.selected,
                    wrapper: classes.tabLabel
                  }}
                />
              ))
            : null}
        </VerticalTabs>

        {INFO_TABS_CONTENT.map(
          (tab, index) =>
            activeIndex === index && (
              <SingleInfoTab
                key={tab.tabHeader}
                editCompetitionInfo={tab.editCompetitionInfo}
                generalInfo={tab.generalInfo}
                editorState={tab.editorState}
                changeEditorState={tab.changeEditorState}
                createMarkup={tab.createMarkup}
                tabHeader={tab.tabHeader}
              />
            )
        )}
        {!!documents
          ? Object.values(documents).map(
              (extraTab, index) =>
                activeIndex === index + INFO_TABS.length && (
                  <div
                    key={extraTab.id}
                    className={classes.extraTabContainer}
                    style={{
                      height: extraTab.pageHeight
                    }}
                  >
                    <iframe
                      id={extraTab.id}
                      title={extraTab.label}
                      src={extraTab.iframeLink}
                      width="100%"
                      height="100%"
                      loading="eager"
                      frameBorder="0"
                      scrolling="no"
                    />
                  </div>
                )
            )
          : null}
      </div>
      {profile.role !== 'judge' && (
        <div className="d-flex align-items-center justify-content-end mb-3">
          {!editCompetitionInfo && activeIndex !== INFO_TABS.length && (
            <Button
              variant="contained"
              color="primary"
              size="md"
              onClick={editCompetition}
            >
              {t('Edit')}
            </Button>
          )}
          {editCompetitionInfo && profile.role !== 'judge' && (
            <div className="d-flex align-items-center">
              <Button
                variant="outlined"
                color="primary"
                size="md"
                onClick={closeEditCompetition}
              >
                {t('Cancel')}
              </Button>
              <Button
                variant="contained"
                style={{ marginLeft: '8px' }}
                color="primary"
                size="md"
                onClick={saveEditCompetition}
              >
                {t('Save')}
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

const mapStateToProps = state => {
  return {
    profile: state.firebase.profile,
    generalInfo: state.firestore.ordered.generalInfo
      ? state.firestore.ordered.generalInfo[0]
      : {}
  }
}

const mapDispatchToProps = dispatch => {
  return {
    addToDataBase: (coll, doc, data) => dispatch(addToDataBase(coll, doc, data))
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect([{ collection: 'generalInfo' }])
)(withSnackbar(withStyles(styles)(CompetitionInfo)))
