import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { filterProposals } from '../../redux/actions/proposalsActions';
import MultipleSelect from '../../inputs/MultipleSelect';
import * as searchHelper from '../../util/searchHelper';
import { useTranslation } from 'react-i18next';

const PrivateTags = ({ filterProposals, history, profile }) => {
  const [name, setName] = React.useState([]);
  const [publicTagFilterBy, setPublicTagFilterBy] = React.useState(null);
  const { t } = useTranslation('navbar');

  // React.useEffect(() => {
  //   const { search } = history.location;

  //   if (!search) setName([]);
  // }, [history.location]);

  React.useEffect(() => {
    const { search } = history.location;
    if (!search) setName([]);
    const searchQuery = searchHelper.getSearchQuery(search);

    if (searchQuery.private_tags) {
      console.log('private_tags:', searchQuery.private_tags);
      const privateTags = searchQuery.private_tags.split(',');
      setName(privateTags);
    }
  }, [history.location]);

  const handleChange = event => {
    const { value } = event.target;

    if (value[value.length - 1] !== 'none') setName(value);
  };

  const selectAll = privateTags => {
    const values = privateTags.map(privateTag => privateTag.name);

    setName(values);
  };

  const clearAll = () => setName([]);

  const applyFilters = () => {
    history.push({
      search: searchHelper.updateSearchQuery(
        history.location.search,
        'private_tags',
        name
      ),
    });

    filterProposals(history.location.search, publicTagFilterBy);
    // filterProposals(history.location.search);
  };

  const privateTags = () => {
    const tags = profile.PrivateTags
      ? profile.PrivateTags.map(val => {
          const proposalsHaveTags = _.filter(
            profile.proposals,
            proposal => proposal.tags
          );
          const proposalsTags = _.map(proposalsHaveTags, 'tags');
          const allTags = [].concat.apply([], proposalsTags);
          const amount = _.filter(allTags, ['id', val.id]);

          return {
            name: val.name,
            amount: amount.length,
          };
        })
      : [];

    const tagsInUse = tags.filter(tag => tag.amount > 0);

    return tagsInUse;
  };

  return (
    <div id="filter-private-tags">
      <MultipleSelect
        options={privateTags()}
        values={name}
        onChange={handleChange}
        selectAll={() => selectAll(privateTags())}
        clearAll={clearAll}
        placeholder={t('My private tags')}
        apply={applyFilters}
        publicTagFilterBy={publicTagFilterBy}
        setPublicTagFilterBy={setPublicTagFilterBy}
      />
    </div>
  );
};

PrivateTags.propTypes = {
  history: PropTypes.objectOf(Object).isRequired,
  filterProposals: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    profile: state.firebase.profile,
    proposals: state.firestore.ordered.proposals,
  };
};

export default compose(
  connect(mapStateToProps, { filterProposals }),
  firestoreConnect([{ collection: 'proposals' }])
)(withRouter(PrivateTags));
