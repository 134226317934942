import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core/styles';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Text from './Text';
import Button from './Button';
import { useTheme } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';

const checker = (arr, target) => target.every(v => arr.includes(v));

const styles = theme => ({
  listHeader: {
    outline: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0px 8px 8px',
    backgroundColor: theme.modeColors.inputBackground,
  },
  listFooter: {
    position: 'absolute',
    bottom: 0,
    outline: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '8px 16px 8px 8px',
    backgroundColor: theme.modeColors.inputBackground,
    borderTop: '1px solid #e0e0e0',
    width: '100%',
  },
  menuItem: {
    margin: '4px 8px',
    padding: '0px 8px',
    minHeight: 24,
    backgroundColor: theme.modeColors.inputBackground,
  },
  checkbox: {
    padding: 0,
    paddingRight: 8,
  },
});

// Advanced search by elements
const MultipleSelect = ({
  placeholder,
  options,
  values,
  onChange,
  clickGroupOptions,
  clickOption,
  selectAll,
  clearAll,
  classes,
  apply,
}) => {
  const [open, setOpen] = React.useState(false);
  const { t } = useTranslation('navbar');

  const theme = useTheme();

  const MenuProps = {
    MenuListProps: {
      style: {
        position: 'relative',
        overflow: 'auto',
        paddingBottom: 50,
        backgroundColor: theme.modeColors.inputBackground,
      },
    },
  };

  const applyChange = () => {
    apply();
    handleClose();
  };

  function handleClose() {
    setOpen(false);
  }

  function handleOpen() {
    setOpen(true);
  }

  return (
    <Select
      multiple
      displayEmpty
      open={open}
      onClose={handleClose}
      onOpen={handleOpen}
      value={values}
      onChange={onChange}
      input={<Text active={values.length} id="select-multiple-checkbox" size="md" />}
      MenuProps={
        (MenuProps,
        { disablePortal: true, disableAutoFocus: true, disableEnforceFocus: true })
      }
      renderValue={selected => {
        if (selected.length === 0) {
          return <span>{placeholder}</span>;
        }

        return selected.join(', ');
      }}
    >
      <MenuItem className={classes.listHeader}>
        <Button onClick={() => selectAll()} size="md" color="primary">
          {t('Select all')}
        </Button>
        <Button onClick={() => clearAll()} size="md">
          {t('Clear all')}
        </Button>
      </MenuItem>
      <Divider />
      {_.map(options, (val, key) => {
        return (
          <div key={key}>
            <MenuItem
              name="test2"
              disableGutters={true}
              onClick={() => clickGroupOptions(key)}
              classes={{
                root: classes.menuItem,
              }}
            >
              <Checkbox
                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                checkedIcon={<CheckBoxIcon fontSize="small" />}
                checked={checker(values, val)}
                classes={{
                  root: classes.checkbox,
                }}
                color="default"
              />
              <div className="sm">{key} </div>
            </MenuItem>
            <div>
              {val.map(option => (
                <MenuItem
                  onClick={() => clickOption(option)}
                  disableGutters={true}
                  key={option}
                  value={option}
                  className="mx-3"
                  classes={{
                    root: classes.menuItem,
                  }}
                >
                  <Checkbox
                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    checked={values.indexOf(option) > -1}
                    classes={{
                      root: classes.checkbox,
                    }}
                    color="default"
                  />

                  <div className="d-flex full-width align-items-center">
                    <div className="sm">{option} </div>
                  </div>
                </MenuItem>
              ))}
            </div>
          </div>
        );
      })}
      <MenuItem value="none" className={classes.listFooter}>
        <Button
          style={{ marginLeft: 'auto' }}
          variant="outlined"
          color="primary"
          onClick={applyChange}
          size="sm"
        >
          {t('Apply')}
        </Button>
      </MenuItem>
    </Select>
  );
};

MultipleSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
  options: PropTypes.object.isRequired,
  values: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  selectAll: PropTypes.func.isRequired,
  clearAll: PropTypes.func.isRequired,
};

export default withStyles(styles)(MultipleSelect);
