import * as React from 'react'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import _ from 'lodash'
import { makeStyles } from '@material-ui/core'

import Spinner from '../../spinner/Spinner'
import { updateDataBase } from '../../redux/actions/dataBase'
import { groupCommentsByDate, groupCommentsByProposal } from './Helpers'
import GroupCommentsByProposal from './GroupCommentsByProposal'
import GroupCommentsByDate from './GroupCommentsByDate'
import { useTranslation } from 'react-i18next'

function CommentsCards({
  comments,
  secondPhaseComments,
  updateDataBase,
  proposals,
  profile
}) {
  const selectedPhase = JSON.parse(
    window.localStorage.getItem('jt-selected-phase')
  )
  const collectionName =
    selectedPhase && selectedPhase === 'phase-2'
      ? 'second-phase-comments'
      : 'comments'
  const commentsData =
    selectedPhase === 'phase-2' ? secondPhaseComments : comments
  const { t } = useTranslation('proposals')
  const [sortedComments, setSortedComments] = React.useState([])
  const classes = useStyles()
  const isSortByProposal = profile.sortCommentsBy === 'proposal' || false

  React.useEffect(() => {
    if (!_.isEmpty(commentsData)) {
      const result = isSortByProposal
        ? groupCommentsByProposal(commentsData, proposals.data)
        : groupCommentsByDate(commentsData)
      setSortedComments(result)
    }

    if (!commentsData) {
      return setSortedComments([])
    }

    return () => {
      // When user goes away from 'Comments' tab, remove 'new icon' from each comment
      sortedComments.forEach(obj => {
        obj.comments.forEach(comment =>
          updateDataBase(collectionName, comment?.commentId, {
            ...comment,
            isNew: false
          })
            .then(() => {})
            .catch(error => console.log(error))
        )
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commentsData, isSortByProposal, proposals.data])

  if (_.isEmpty(sortedComments)) {
    return <div className="p-1">{t('No comments')}</div>
  }

  if (!sortedComments.length) {
    return <Spinner />
  }

  return isSortByProposal ? (
    <GroupCommentsByProposal
      sortedComments={sortedComments}
      classes={classes}
    />
  ) : (
    <GroupCommentsByDate sortedComments={sortedComments} classes={classes} />
  )
}

const useStyles = makeStyles(theme => ({
  wrapper: {
    position: 'relative',
    borderTop: '1px solid #e0e2e4',
    width: '100%',
    padding: '3rem 0'
  },
  groupTitle: {
    position: 'absolute',
    top: '-18px',
    left: '50%',
    transform: 'translateX(-50%)',
    fontSize: '16px',
    marginBottom: 0,
    padding: '7px 10px 5px',
    lineHeight: 1.5,
    backgroundColor: '#f7f8f9',
    color: 'inherit',
    border: '1px solid black',
    borderRadius: '20px'
  },
  groupTitleOnHover: {
    transition: 'all 0.2s ease',

    '&:hover': {
      backgroundColor: 'black',
      color: 'white'
    }
  }
}))

const mapStateToProps = state => ({
  proposals: state.proposals,
  comments: state.firestore.data.comments,
  secondPhaseComments: state.firestore.data['second-phase-comments'],
  profile: state.firebase.profile
})

const mapDispatchToProps = dispatch => {
  return {
    updateDataBase: (coll, doc, data) =>
      dispatch(updateDataBase(coll, doc, data))
  }
}

export default compose(
  firestoreConnect([
    { collection: 'comments', orderBy: ['createdAt', 'desc'] },
    { collection: 'second-phase-comments', orderBy: ['createdAt', 'desc'] }
  ]),
  connect(mapStateToProps, mapDispatchToProps)
)(CommentsCards)
