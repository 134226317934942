import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import _ from 'lodash'
import { SnackbarProvider } from 'notistack'
import {
  StylesProvider,
  ThemeProvider as MuiThemeProvider,
  createTheme
} from '@material-ui/core'

import useLocalStorage from './hooks/useLocalStorage'
import {
  getProposals,
  setPhaseId,
  setXdTwinSecret
} from './redux/actions/proposalsActions'
import Root from './root/Root'
import './App.scss'

const lightTheme = createTheme({
  modeColors: {
    textColor: '#000000',
    rootBackGround: '#f7f8f9',
    inputBorder: '#ccced0',
    guttersGridBg: '#ffffff',
    guttersGridAdvancedSearchColor: '#353c43',
    checkboxLabel: '#000000',
    checkbox: 'rgba(0, 0, 0, 0.54)',
    proposalTitle: '#353c43',
    tabBackground: 'transparent',
    selectedTabBackground: '#e0e2e4',
    singleProposalSideNavBg: '#ffffff',
    singleProposalSideNavText: 'inherit',
    buttonLabel: '#000000',
    elementImageContainer: '#6e7072',
    carouselBg: '#999da0',
    searchDetailsTitle: '#353c43',
    inputBackground: '#ffffff',
    navBackground: '#ffffff',
    cardBackground: '#ffffff',
    iconBtnBackground: '#ffffff',
    iconBtnBorder: '#bfbfbf',
    expandItemBackground: '#ffffff',
    headingColor: '#353c43',
    selectedColor: '#3f51b5'
  }
})

const darkTheme = createTheme({
  modeColors: {
    textColor: '#f7f8f9',
    rootBackGround: '#000000',
    inputBorder: '#ffffff',
    guttersGridBg: '#2e2e2e',
    guttersGridAdvancedSearchColor: '#f7f8f9',
    checkboxLabel: '#f7f8f9',
    checkbox: '#bfbfbf',
    proposalTitle: '#f7f8f9',
    tabBackground: '#ccced0',
    selectedTabBackground: '#8790c3',
    singleProposalSideNavBg: '#2e2e2e',
    singleProposalSideNavText: '#f7f8f9',
    buttonLabel: '#f7f8f9',
    elementImageContainer: '#000000',
    carouselBg: '#2e2e2e',
    searchDetailsTitle: '#f7f8f9',
    inputBackground: '#bfbfbf',
    navBackground: '#bfbfbf',
    cardBackground: '#bfbfbf',
    iconBtnBackground: '#bfbfbf',
    iconBtnBorder: '#ffffff',
    expandItemBackground: '#2e2e2e',
    headingColor: '#f7f8f9',
    selectedColor: '#8790c3'
  }
})

const App = ({ proposals, getProposals, auth, profile, setXdTwinSecret }) => {
  const [themeMode, setThemeMode] = useState('light')
  const [, setLanguage] = useLocalStorage('selectedLanguage', 'fi')

  const toggleState = mode => setThemeMode(mode)

  useEffect(() => {
    if (proposals.loading === undefined) {
      if (auth && auth.uid) {
        getProposals()
      }
    }
  }, [proposals.loading, getProposals, auth])

  useEffect(() => {
    if (!_.isEmpty(profile)) {
      setLanguage(profile?.selectedLanguage || 'fi')
    }
  }, [profile, setLanguage])

  return (
    <Router>
      <SnackbarProvider maxSnack={5}>
        <StylesProvider injectFirst>
          <MuiThemeProvider
            theme={themeMode === 'light' ? lightTheme : darkTheme}
          >
            <ThemeProvider
              theme={themeMode === 'light' ? lightTheme : darkTheme}
            >
              <Root themeMode={themeMode} toggleState={toggleState} />
            </ThemeProvider>
          </MuiThemeProvider>
        </StylesProvider>
      </SnackbarProvider>
    </Router>
  )
}

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    proposals: state.proposals,
    profile: state.firebase.profile,
    selectedPhase: state.proposals.selectedPhase,
    xdTwinSecret: state.proposals.xdTwinSecret
  }
}

export default connect(mapStateToProps, {
  getProposals,
  setPhaseId,
  setXdTwinSecret
})(App)
