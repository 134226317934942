import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';
import { connect } from 'react-redux';
import _ from 'lodash';
import ListIcon from '@material-ui/icons/FormatListBulleted';
import GridIcon from '@material-ui/icons/Apps';
import TableChartIcon from '@material-ui/icons/TableChart';
import CommentIcon from '@material-ui/icons/Comment';
import { useTheme } from '@material-ui/styles';

import Button from '../inputs/Button';

const ViewMode = ({ toggleView, activeView, comments }) => {
  const [totalNewComments, setTotalNewComments] = React.useState(0);
  const theme = useTheme();

  React.useEffect(() => {
    const calculateTotalNewComments = () => {
      if (!_.isEmpty(comments)) {
        const newComments = _.filter(comments, comment => comment?.isNew === true);
        const total = newComments.length;
        setTotalNewComments(total);
      }
      return;
    };

    calculateTotalNewComments();
  }, [comments]);

  return (
    <div className="mx-2">
      <Button
        id="proposal-cards"
        onClick={() => toggleView('grid')}
        variant="contained"
        style={{
          backgroundColor: theme.modeColors.iconBtnBackground,
          border:
            activeView === 'grid'
              ? '2px solid #000'
              : `1px solid ${theme.modeColors.iconBtnBorder}`,
        }}
        size="xs"
      >
        <GridIcon />
      </Button>

      <Button
        id="list-view"
        onClick={() => toggleView('list')}
        variant="contained"
        style={{
          backgroundColor: theme.modeColors.iconBtnBackground,
          border:
            activeView === 'list'
              ? '2px solid #000'
              : `1px solid ${theme.modeColors.iconBtnBorder}`,
        }}
        size="xs"
      >
        <ListIcon />
      </Button>

      <Button
        id="info-table"
        onClick={() => toggleView('table')}
        variant="contained"
        style={{
          backgroundColor: theme.modeColors.iconBtnBackground,
          border:
            activeView === 'table'
              ? '2px solid #000'
              : `1px solid ${theme.modeColors.iconBtnBorder}`,
        }}
        size="xs"
      >
        <TableChartIcon />
      </Button>

      <Button
        id="comments"
        onClick={() => toggleView('comments')}
        variant="contained"
        style={{
          position: 'relative',
          backgroundColor: theme.modeColors.iconBtnBackground,
          border:
            activeView === 'comments'
              ? '2px solid #000'
              : `1px solid ${theme.modeColors.iconBtnBorder}`,
        }}
        size="xs"
      >
        <>
          <CommentIcon />
          {activeView !== 'comments' && totalNewComments > 0 ? (
            <div
              style={{
                position: 'absolute',
                right: '-15px',
                top: '-15px',
                padding: `${
                  totalNewComments >= 100 ? '7px 4px 5px 4px' : '4px 4px 1px 4px'
                }`,
                background: 'red',
                color: 'white',
                borderRadius: '100%',
                fontSize: '13px',
                lineHeight: 1.5,
                fontWeight: 700,
                minWidth: '15px',
                textAlign: 'center',
              }}
            >
              {totalNewComments}
            </div>
          ) : null}
        </>
      </Button>
    </div>
  );
};

ViewMode.propTypes = {
  toggleView: PropTypes.func.isRequired,
  activeView: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  comments: state.firestore.data.comments,
});

export default compose(
  firestoreConnect([{ collection: 'comments' }]),
  connect(mapStateToProps)
)(ViewMode);
