export const LINKS = {
  1: 'https://api2.enscape3d.com/v1/view/9d7fa498-2585-4d44-ac57-b728f535892c',
  2: 'https://api2.enscape3d.com/v1/view/b6390004-07bb-41fe-a358-78777e7ba67d',
  3: 'https://api2.enscape3d.com/v1/view/b265143f-954b-4b97-a8ec-1441ffff9ca8',
  4: 'https://api2.enscape3d.com/v1/view/63c23175-28eb-4f8f-99f9-cad403898d70',
  5: 'https://api2.enscape3d.com/v1/view/852b0eb2-32d9-4c2b-a41c-ed9766300d74',
  6: 'https://api2.enscape3d.com/v1/view/16106a7d-fd72-4bf2-a873-91b3866124b8',
  7: 'https://api2.enscape3d.com/v1/view/0f81104b-6f3d-45a5-8e6d-9bd822499891',
  8: 'https://api2.enscape3d.com/v1/view/760255fb-a00a-4dda-b289-d1e8f383f819',
  9: 'https://api2.enscape3d.com/v1/view/4651bbfc-a237-47f6-a5e3-7d9b2064989c',
  10: 'https://api2.enscape3d.com/v1/view/9791d563-7924-442a-8f11-e9709f78260c',
  11: 'https://api2.enscape3d.com/v1/view/c3470dd9-2dfd-4248-9529-f6af930fae36',
  12: 'https://api2.enscape3d.com/v1/view/94520e2b-81a7-4a7d-adb0-c2190ba59235',
  13: 'https://api2.enscape3d.com/v1/view/41722c76-6b15-4843-831e-5905e088f87b',
  14: 'https://api2.enscape3d.com/v1/view/8b9ca4ae-5fa1-4a1f-a055-b12f269d6aea',
  15: 'https://api2.enscape3d.com/v1/view/10eaf573-6da2-4b4f-9507-8855691a505a',
  16: 'https://api2.enscape3d.com/v1/view/489d087d-58d0-41c4-868d-6629fdf23331',
  17: 'https://api2.enscape3d.com/v1/view/9795686c-da74-444a-9b84-b51aed2be10b',
  18: 'https://api2.enscape3d.com/v1/view/17b3695d-63d2-4113-bffc-27fd833709e9',
  19: 'https://api2.enscape3d.com/v1/view/6dc4be68-576f-4e27-8c6a-a5becf1b5d38',
  20: 'https://api2.enscape3d.com/v1/view/7d806d2a-6de1-4e84-8995-47ca2f84aac1',
  21: 'https://api2.enscape3d.com/v1/view/ca2cee36-c269-4cb1-baf7-27063ee51252',
  22: 'https://api2.enscape3d.com/v1/view/48aa5c5c-d5b0-40f3-b177-a7bd1c652e4b',
  23: 'https://api2.enscape3d.com/v1/view/309e222b-3af6-4cf6-99a3-ced28c1f3dbe',
  24: 'https://api2.enscape3d.com/v1/view/e921114d-f865-49ac-a78c-5d5494dbeaea',
  25: 'https://api2.enscape3d.com/v1/view/614b48bb-2f8e-4a03-b3fe-ef65ec7af89d',
  26: 'https://api2.enscape3d.com/v1/view/2f81be63-1e26-42e7-a069-9d63fc0e6383',
  27: 'https://api2.enscape3d.com/v1/view/55ccaf2e-f8f5-4872-be89-4ae7809054c5',
  28: 'https://api2.enscape3d.com/v1/view/106916a4-c697-4fbb-be31-1825b4fc6f9f',
  29: 'https://api2.enscape3d.com/v1/view/e82ae925-caea-4de3-a8a7-66ae2fcc3fa2',
  30: 'https://api2.enscape3d.com/v1/view/29416589-4a50-4d1c-ad8a-466754952111',
  31: 'https://api2.enscape3d.com/v1/view/bfc09dab-59b4-4821-bcce-9b38a648be19',
  32: 'https://api2.enscape3d.com/v1/view/fca36268-6d23-465e-ac1e-e391fe78805f',
  33: 'https://api2.enscape3d.com/v1/view/1fec3d95-dfb6-406b-8bf5-231698f011ae',
  34: 'https://api2.enscape3d.com/v1/view/68bf0442-2086-4614-bfe4-0ea0c03bed44',
  35: 'https://api2.enscape3d.com/v1/view/3a2b3a05-2919-49da-aeec-fbe37a907f66',
  36: 'https://api2.enscape3d.com/v1/view/9ebfff9f-d9cf-434e-88ae-cd3bf2f80e78',
  37: 'https://api2.enscape3d.com/v1/view/9de0c071-3bcc-4b17-9502-7611acb44229',
  38: 'https://api2.enscape3d.com/v1/view/8b499701-1d22-4539-a281-c6c07cbc4245',
  39: 'https://api2.enscape3d.com/v1/view/e6b7b774-8790-4a0b-8cd2-4fa57c548a30',
  40: 'https://api2.enscape3d.com/v1/view/98eca2fa-1ee9-4151-90e2-2bb0768ddf0e',
  41: 'https://api2.enscape3d.com/v1/view/970cc81b-0f7f-4ab2-bb55-2f3e4a908266',
  42: 'https://api2.enscape3d.com/v1/view/3d4d27fe-b25d-4ac7-813a-6efa6db514b7',
  43: 'https://api2.enscape3d.com/v1/view/ff999821-e485-433e-bfa7-10156f6a3f36',
  44: 'https://api2.enscape3d.com/v1/view/b65f1031-0c86-460a-a23b-f1f54b03d098',
  45: 'https://api2.enscape3d.com/v1/view/124922af-9291-4057-b082-c46ba4c33136',
  46: 'https://api2.enscape3d.com/v1/view/267c8b64-d4f5-42f7-85bb-4c082c8658c7',
  47: 'https://api2.enscape3d.com/v1/view/efc479eb-abd3-4376-956f-9b8648497652',
  48: 'https://api2.enscape3d.com/v1/view/ee3643bd-4b5c-4720-82ec-b5f34b88dcbd',
  49: 'https://api2.enscape3d.com/v1/view/42b521f3-0771-412a-aa9c-1962ff1532cd',
  50: 'https://api2.enscape3d.com/v1/view/a0aff2fb-ddb3-4243-b61a-382c10f12fa9',
  51: 'https://api2.enscape3d.com/v1/view/6d51e9c2-d617-4b5b-8358-44a29c217558',
  52: 'https://api2.enscape3d.com/v1/view/3f6436d2-56b2-449f-82ba-ba5fcd3f1fd1',
  53: 'https://api2.enscape3d.com/v1/view/56203618-29ba-4b62-a5e8-2136168ed8a9',
  54: 'https://api2.enscape3d.com/v1/view/200b4393-e4ca-4393-89fb-0a8bd22f031f',
  55: 'https://api2.enscape3d.com/v1/view/605ec129-f6c0-4012-9bdd-60a12a7b3cd7',
  56: 'https://api2.enscape3d.com/v1/view/8a2bab7c-b733-475f-99e4-af012a2c1de7',
  57: 'https://api2.enscape3d.com/v1/view/fe30594c-5137-452c-a22f-919d3f312398',
  58: 'https://api2.enscape3d.com/v1/view/760806dc-3816-4535-a42f-acd8577dfaab',
  59: 'https://api2.enscape3d.com/v1/view/268f23f2-6256-432c-8841-8ab159e59b47',
  60: 'https://api2.enscape3d.com/v1/view/32e920bd-6e1c-46dc-bca5-9eb82a472c02',
  61: 'https://api2.enscape3d.com/v1/view/40a4682b-1cea-4af2-a636-e64f9f088b65',
  62: 'https://api2.enscape3d.com/v1/view/12d95254-a30e-48b0-8364-607ee63511eb',
  63: 'https://api2.enscape3d.com/v1/view/4ab4a89c-c3e9-4990-b603-54ca876742af',
  64: 'https://api2.enscape3d.com/v1/view/90086b8f-ca1b-4cf8-8f36-21fa1617f7c2',
  65: 'https://api2.enscape3d.com/v1/view/c1a5f1c8-669e-43da-a5ff-de6c0693df49',
  66: 'https://api2.enscape3d.com/v1/view/aff4c348-7aad-4626-ab82-a9fd7bc9fcca',
  67: 'https://api2.enscape3d.com/v1/view/c51bb83e-abeb-4137-89a1-b9f5230b6e0a',
  68: 'https://api2.enscape3d.com/v1/view/b9ee4508-b2eb-417e-ae27-ba728ece5561',
  69: 'https://api2.enscape3d.com/v1/view/8ac44ada-a6a1-45cf-a7be-d24c3eebbf8c',
  70: 'https://api2.enscape3d.com/v1/view/9e81da97-f204-4e53-a5fb-e153c6ff419e',
  71: 'https://api2.enscape3d.com/v1/view/e830045b-a5bf-4677-b3b8-4b51f7b12715',
  72: 'https://api2.enscape3d.com/v1/view/55a0de32-fdca-441d-8626-7ee436fc6513',
  73: 'https://api2.enscape3d.com/v1/view/4c268570-a656-4eda-813d-4023ac217168',
  74: 'https://api2.enscape3d.com/v1/view/f8a24d6f-d8a5-4493-a889-39c44b80f775',
  75: 'https://api2.enscape3d.com/v1/view/2652b2d3-a3d2-4a20-919f-7d79fa1c3230',
  76: 'https://api2.enscape3d.com/v1/view/0a4096ad-380d-4588-bbbc-322e4bc3fce5',
  77: 'https://api2.enscape3d.com/v1/view/ba7371b2-a852-4773-86d0-059e77e63901',
  78: 'https://api2.enscape3d.com/v1/view/4d168f08-5ba8-4c5b-b4e1-5f48a6092fe3',
  79: 'https://api2.enscape3d.com/v1/view/4e7c1116-a24c-4b7f-9b97-01320db1f738',
  80: 'https://api2.enscape3d.com/v1/view/c705ecff-2433-405c-b6e9-58a2bb26c436',
  81: 'https://api2.enscape3d.com/v1/view/9afff8a6-d9a5-4e5a-86d3-4163c7c9c632',
  82: 'https://api2.enscape3d.com/v1/view/bac49afa-a74a-41c9-9a75-ce867cb2347f',
  83: 'https://api2.enscape3d.com/v1/view/dd4184d0-170e-4924-bc75-dec54836ba7c',
  84: 'https://api2.enscape3d.com/v1/view/02092493-0864-4e05-bf7b-e9d53e055f51',
  85: 'https://api2.enscape3d.com/v1/view/264b3145-0c31-455e-b7cf-e968d30e9244',
  86: 'https://api2.enscape3d.com/v1/view/9c7ed242-bd12-424f-9cac-11bfd49ddeb9',
  87: 'https://api2.enscape3d.com/v1/view/1ab8d9ad-3d58-415e-803d-284d24884666',
  88: 'https://api2.enscape3d.com/v1/view/8ec784c3-4228-4c2e-8243-52bdca1974ee',
  89: 'https://api2.enscape3d.com/v1/view/fafe70a0-ef26-40c6-947a-547103400a54',
  90: 'https://api2.enscape3d.com/v1/view/e70291a0-56fd-42c1-a19a-7accbfb39d2a',
  91: 'https://api2.enscape3d.com/v1/view/21cc083b-dbb9-4c91-8889-57eb8e30634c',
  92: 'https://api2.enscape3d.com/v1/view/0f50d765-803c-4e78-85b4-262ffa1e15ae',
  93: 'https://api2.enscape3d.com/v1/view/d7f368dc-826e-4007-824d-a8e61c115623',
  94: 'https://api2.enscape3d.com/v1/view/31d0e9cf-8990-43a6-a597-bde3ff87a297',
  95: 'https://api2.enscape3d.com/v1/view/28a17e48-1d4a-4187-a825-685603aeeae3',
  96: 'https://api2.enscape3d.com/v1/view/df74c962-8d86-4b75-b12f-41080b27bd23',
  97: 'https://api2.enscape3d.com/v1/view/fe3c616d-5a16-4ff6-87c9-66bd585319c8',
  98: 'https://api2.enscape3d.com/v1/view/bcf74b15-c4d8-472c-8a94-828bdf36d4c2',
  99: 'https://api2.enscape3d.com/v1/view/b883ef35-2fca-43e4-ba40-d58f64fee1a8',
  100: 'https://api2.enscape3d.com/v1/view/53adf18b-6ae7-413a-af3d-1dad568ee160',
  101: 'https://api2.enscape3d.com/v1/view/197f199f-71e2-4584-9046-6bd5dbe428a4',
  102: 'https://api2.enscape3d.com/v1/view/096d443d-3415-4b6f-b5ca-f68ee55e21ec',
  103: 'https://api2.enscape3d.com/v1/view/9e2501da-20d2-41de-8754-8d2a9ae97c39',
  104: 'https://api2.enscape3d.com/v1/view/2fa1063c-4e74-4ad2-8d8e-6f3282f1fab2',
  105: 'https://api2.enscape3d.com/v1/view/58874ebd-e628-4b42-9a27-9bf30718a09b',
  106: 'https://api2.enscape3d.com/v1/view/7c022b85-cb82-4ae9-9685-76134bd58d12',
  107: 'https://api2.enscape3d.com/v1/view/46383f7e-1096-444d-a1bd-5cf1c15989e8',
  108: 'https://api2.enscape3d.com/v1/view/60da44b8-b764-4da3-ac8c-174872d5181e',
  109: 'https://api2.enscape3d.com/v1/view/4de20011-22a8-4cf6-9d10-819a44115974',
  110: 'https://api2.enscape3d.com/v1/view/c406254b-da65-46a8-9a72-e944cf8eaff6',
  111: 'https://api2.enscape3d.com/v1/view/8b03bf1b-3ae2-4709-add8-09b1cce7899e',
  112: 'https://api2.enscape3d.com/v1/view/fdf5efce-9216-41ae-9a74-d03fdb3555ec',
  113: 'https://api2.enscape3d.com/v1/view/5239d0a5-589a-4693-886f-d40cd8865e21',
  114: 'https://api2.enscape3d.com/v1/view/76dce2d4-3199-46a8-a345-3483447afe04',
  115: 'https://api2.enscape3d.com/v1/view/3d62c3dc-06bd-4965-ae79-73ad4783a205',
  116: 'https://api2.enscape3d.com/v1/view/0ae7d8b9-a480-4e84-8071-fd293cc68f15',
  117: 'https://api2.enscape3d.com/v1/view/2559caa6-397b-46ae-8d38-0b99d278f2f9',
  118: 'https://api2.enscape3d.com/v1/view/c9a0e3d5-2cec-4087-b5d9-7467b54b8065',
  119: 'https://api2.enscape3d.com/v1/view/3dca6dd9-045c-4d39-90d5-b5b70d6d8d22',
  120: 'https://api2.enscape3d.com/v1/view/fb7580ad-5e69-4e9d-8022-83ae6ea73d38',
  121: 'https://api2.enscape3d.com/v1/view/70663012-3741-4196-bfc0-73410ebaeed2',
  122: 'https://api2.enscape3d.com/v1/view/3ead1db7-33ff-4907-8f1d-1078d72381c8',
  123: 'https://api2.enscape3d.com/v1/view/9ab27785-9b47-4644-bea0-18fd947ac82d',
  124: 'https://api2.enscape3d.com/v1/view/48ea24ca-0064-46fd-a22d-78edca3a6aa0',
  125: 'https://api2.enscape3d.com/v1/view/32c76ff2-78e8-43d1-aaae-4e064fdbec2b',
  126: 'https://api2.enscape3d.com/v1/view/5f2ca208-abfe-4606-8e02-776bdd03aa67',
  127: 'https://api2.enscape3d.com/v1/view/75532757-71e4-4d86-848e-c70152b11baa',
  128: 'https://api2.enscape3d.com/v1/view/38b4db8d-937e-4e4c-afea-7088c4d930a1',
  129: 'https://api2.enscape3d.com/v1/view/ea0f6bae-73d0-44a1-95a6-a72bfadb1235',
  130: 'https://api2.enscape3d.com/v1/view/988b2ef8-2185-4140-9de9-394698ba385f',
  131: 'https://api2.enscape3d.com/v1/view/3076be63-d0d1-4425-ae07-60889f62e777',
  132: 'https://api2.enscape3d.com/v1/view/3ac4051c-fafd-4275-b78e-a9e6c4bb8d2d',
  133: 'https://api2.enscape3d.com/v1/view/dff8a4a1-6b05-4a4d-9f2e-e6d495fc3a77',
  134: 'https://api2.enscape3d.com/v1/view/4367517a-be82-4fc5-9ed1-afb6f831ef59',
  135: 'https://api2.enscape3d.com/v1/view/ebeb5272-83c5-4d8f-8de7-5dd0fc40142c',
  136: 'https://api2.enscape3d.com/v1/view/5a7b16d6-1aa9-4307-b273-c0d096f1f497',
  137: 'https://api2.enscape3d.com/v1/view/8991ccb4-abc6-4318-9e18-e663948ad563',
  138: 'https://api2.enscape3d.com/v1/view/d1a49b95-189c-4168-a2db-7234c020dfb5',
  139: 'https://api2.enscape3d.com/v1/view/1f870a71-9f63-4821-aff7-f957df18ef05',
  140: 'https://api2.enscape3d.com/v1/view/5de82625-e13d-4176-9fa7-e6e6039e0d01',
  141: 'https://api2.enscape3d.com/v1/view/9efa6576-ae86-42a1-a5a4-c24ae22c18a0',
  142: 'https://api2.enscape3d.com/v1/view/d9b54cd4-6e42-4dd5-950e-47d7610dfe9b',
  143: 'https://api2.enscape3d.com/v1/view/a6361484-a9ab-4c13-acd6-093ed650dede',
  144: 'https://api2.enscape3d.com/v1/view/ee2a0d18-ad4d-4973-942f-cf703598a5b3',
  145: 'https://api2.enscape3d.com/v1/view/2d0e05bb-4b6d-4add-a4d2-4582eefe9daf',
  146: 'https://api2.enscape3d.com/v1/view/869ae54f-f175-4688-b2d7-7e719b445ac5',
  147: 'https://api2.enscape3d.com/v1/view/536e6fa3-d460-46a3-af90-f92a012dc32d',
  148: 'https://api2.enscape3d.com/v1/view/ee0702b5-eed9-4ddc-af99-788781b5b289',
  149: 'https://api2.enscape3d.com/v1/view/04881594-a653-4f7c-8798-4602e8dc050c',
  150: 'https://api2.enscape3d.com/v1/view/dfd197f6-fc02-4539-8fa1-007e81c17437',
  151: 'https://api2.enscape3d.com/v1/view/a4451ba4-4efc-498c-abcc-2166af496927',
  152: 'https://api2.enscape3d.com/v1/view/32e932c3-fda2-4619-a384-6ad186825282',
  153: 'https://api2.enscape3d.com/v1/view/749b516b-b9d0-41c4-ac86-686a9458c091',
  154: 'https://api2.enscape3d.com/v1/view/930280bd-327a-4800-9101-330dc0e76579',
  155: 'https://api2.enscape3d.com/v1/view/fe4a5ae9-2555-40ea-9a85-22f1feb4462e',
  156: 'https://api2.enscape3d.com/v1/view/a04f7268-55fa-4c08-b455-8044a2dc3f9d',
  157: 'https://api2.enscape3d.com/v1/view/478f8f9a-8a4a-426c-aadc-bc3ed2b5be2f',
  158: 'https://api2.enscape3d.com/v1/view/378e8872-1d15-44ea-8f9f-86fff28638a8',
  159: 'https://api2.enscape3d.com/v1/view/9fbfe7db-5518-44ff-92bd-0d266c08e1ee',
  160: 'https://api2.enscape3d.com/v1/view/49c8549a-4081-4527-ab0a-6344f6c94277',
  161: 'https://api2.enscape3d.com/v1/view/86539818-6892-424b-8aae-e60666fb0733',
  162: 'https://api2.enscape3d.com/v1/view/9c14c522-5103-4cd7-b016-fc2fd3e60dd8',
  163: 'https://api2.enscape3d.com/v1/view/5588347e-f41e-4a9a-b91d-71f5ef1c5692',
  164: '',
  165: 'https://api2.enscape3d.com/v1/view/2e59e871-19c6-447a-974d-3d238123f35d',
  166: 'https://api2.enscape3d.com/v1/view/72d4ab81-53c2-44eb-9f6f-9c3d7c953bf6',
  167: 'https://api2.enscape3d.com/v1/view/7e65cd55-30b1-4ad6-9cb4-136fd36295ba',
  168: 'https://api2.enscape3d.com/v1/view/a37c5182-65c1-4815-a7e1-ac247859c104',
  169: '',
  170: 'https://api2.enscape3d.com/v1/view/72c8ef3e-f7c1-4a2c-8164-ef1c5208afe9',
  171: 'https://api2.enscape3d.com/v1/view/d17fc91b-35f2-43b1-a26b-df410a1f4053',
  172: 'https://api2.enscape3d.com/v1/view/b68b9d27-335b-46b1-b777-a9d49e8cd30e',
};

export const PHASE_2_LINKS = {
  61: 'https://api2.enscape3d.com/v1/view/08fd9961-7f00-424f-a29e-1884978fbe83',
  80: 'https://api2.enscape3d.com/v1/view/7fbd8b4e-cf8c-4fe6-b436-8dc4f98c959f',
  87: 'https://api2.enscape3d.com/v1/view/fed3757b-9938-4518-9923-c13eb3d0b271',
  91: 'https://api2.enscape3d.com/v1/view/da9cd351-2a55-4ff4-a44c-1a2998d7e82b',
  108: 'https://api2.enscape3d.com/v1/view/3fb4de2b-0b31-49dd-bd3b-184811f6ace9',
};
