import React from 'react';
import { Page, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  mainView: {
    padding: '18px 42px',
  },
  pageDate: {
    position: 'absolute',
    fontSize: 10,
    bottom: 10,
    left: 40,
    right: 0,
    color: 'grey',
  },
});

const PdfSecondPage = ({ images, pageHeader }) => {
  return (
    <Page size="A4" orientation="landscape">
      <View style={styles.mainView}>
        {pageHeader}
        <View style={{ borderTopColor: '#e0e2e4', borderTopWidth: 1 }}>{images}</View>
      </View>
    </Page>
  );
};

export default PdfSecondPage;
