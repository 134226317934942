import _ from 'lodash'
import React from 'react'
import styled from 'styled-components'
import { BiFilterAlt } from 'react-icons/bi'
import { Menu, MenuList, MenuButton, MenuItem } from '@reach/menu-button'
import '@reach/menu-button/styles.css'

import useCollection from '../competition-info/submission/hooks/useCollection'
import ExportToExcel from './ExportToExcel'
import DownloadMaterials from './DownloadMaterials'
import { MdOutlineDownloadForOffline } from 'react-icons/md'
import { FaSpinner } from 'react-icons/fa'
import { useTranslation } from 'react-i18next'

function getProposalValue(object, id) {
  const isHtml = /<\/?[a-z][\s\S]*>/i.test(object[id])
  const textAreaValue = object[`${id}-plain`]
  const value = isHtml ? textAreaValue.slice(0, 500) : object[id]
  const formattedValue = requirementsValue(id, value)
  return formattedValue
}

const extraRequirementItem = {
  description: '',
  displayType: '',
  fileType: '',
  id: 'history',
  limit: '',
  name: 'Timestamp',
  required: false,
  type: 'text',
  unit: ''
}

// status: "valmis"
// timeStamp: "2022-12-02 15:32:30 UTC+2"
// title: "Luotu"

export default function SubmissionStatusTable() {
  const { documents: allProposals } = useCollection('sub-b-proposals')
  const { documents: info } = useCollection('info')
  const [proposals, setProposals] = React.useState({})
  const { t } = useTranslation('proposals')

  React.useEffect(() => {
    if (!!allProposals) {
      setProposals(allProposals)
    }
  }, [allProposals])

  if (!allProposals || !info) {
    return (
      <Wrapper>
        <h1>{t('Submitted proposals')}: 0</h1>
      </Wrapper>
    )
  }

  const shapeRequirements = () => {
    const hasData = info?.submission?.items?.length > 0

    if (hasData) {
      const items = info?.submission?.items.map(item => item)
      items.push(extraRequirementItem)
      return items
    }
    return info?.submission?.items
  }
  const requirements = shapeRequirements()
  const competitionName = info?.general?.name
  const submissionDeadline = info?.general?.deadline

  const shapeExcelData = () => {
    let data = []

    requirements.forEach(({ id, name }) => {
      Object.values(proposals).forEach((proposal, index) => {
        const proposalName = !!proposal.pseudonym
          ? proposal.pseudonym
          : `${t('Proposal')} ${index + 1}`
        const formattedValue = getProposalValue(proposal, id)

        data.push({
          Soveltuvuusvaatimukset: name,
          [proposalName]:
            !!formattedValue && Array.isArray(formattedValue)
              ? createString(formattedValue)
              : formattedValue
        })
      })
    })
    const groupedData = mergeObjects(data, 'Soveltuvuusvaatimukset')

    return groupedData
  }

  const excelData = !!requirements ? shapeExcelData() : []

  const generateWscols = () => {
    const headers = Object.values(proposals).map((proposal, index) => {
      const proposalName = !!proposal.pseudonym
        ? proposal.pseudonym
        : `${t('Proposal')} ${index + 1}`
      return proposalName
    })
    const firstColumn = [
      {
        wch: Math.max(
          ...excelData.map(col => col.Soveltuvuusvaatimukset.length)
        )
      }
    ]
    const columns = headers.map(header => ({
      wch: Math.max(...excelData.map(col => (!!col[header] ? 50 : 0)))
    }))

    return [...firstColumn, ...columns]
  }

  const filteredProposals = key => {
    const result = Object.entries(allProposals).filter(([id, proposal]) => {
      if (key !== 'all' && proposal.proposalStatus === key) {
        return [id, proposal]
      }

      if (key === 'all') {
        return [id, proposal]
      }
    })
    setProposals(Object.fromEntries(result))
  }

  const getMaterialDownloadUrls = () => {
    const arrays = Object.values(allProposals).map((proposal, index) => {
      let urls = []
      const { pseudonym, history } = proposal
      const submittedDate = history[history.length - 1]['timeStamp']
      const proposalName = !!pseudonym
        ? `${pseudonym}-${submittedDate}`
        : `${t('Proposal')} ${index + 1}`

      Object.entries(proposal).forEach(([key, value]) => {
        if (_.isObject(value) && !_.isEmpty(value) && !!value.downloadUrl) {
          urls.push({
            pseudonym: proposalName,
            downloadUrl: value.downloadUrl,
            fileName: value.fileName
          })
        }
      })

      return urls
    })

    return arrays.flat(1)
  }

  return (
    <div>
      <Wrapper>
        <h1>
          {t('Submitted proposals')}: {Object.keys(allProposals).length}
        </h1>
        <div style={{ display: 'flex', gap: 5 }}>
          <FilterOptions handleFilter={filteredProposals} />
          {/* <ExportOptions
            excelData={excelData}
            submissionDeadline={submissionDeadline}
            fileName={`${competitionName} Qualification Table`}
            wscols={generateWscols()}
            urls={getMaterialDownloadUrls()}
          /> */}
        </div>
      </Wrapper>
      <div style={{ marginBottom: 16 }}>
        <h2 style={{ marginBottom: -5, fontSize: '0.9rem' }}>
          {t('Incomplete proposals')}:{' '}
          {
            Object.values(allProposals).filter(
              proposal => proposal.proposalStatus === 'keskeneräinen'
            ).length
          }
        </h2>
        <h2 style={{ marginBottom: 0, fontSize: '0.9rem' }}>
          {t('Complete proposals')}:{' '}
          {
            Object.values(allProposals).filter(
              proposal => proposal.proposalStatus !== 'keskeneräinen'
            ).length
          }
        </h2>
      </div>
      <ProposalsTable tableData={proposals} requirements={requirements} />
    </div>
  )
}

function ProposalsTable({ tableData, requirements }) {
  const { t } = useTranslation('proposals')

  const TableHeader = (
    <thead>
      <TableRow>
        <TableHead>Soveltuvuusvaatimukset</TableHead>
        {Object.entries(tableData).map(
          ([id, { pseudonym, proposalStatus }], index) => (
            <TableHead key={id}>
              {!!pseudonym ? (
                <ProposalNameWrapper>
                  <div>{pseudonym}</div>
                  <Status status={proposalStatus}>{proposalStatus}</Status>
                </ProposalNameWrapper>
              ) : (
                <ProposalNameWrapper>
                  <span>{`${t('Proposal')} ${index + 1}`}</span>
                  <Status status={proposalStatus}>{proposalStatus}</Status>
                </ProposalNameWrapper>
              )}
            </TableHead>
          )
        )}
      </TableRow>
    </thead>
  )

  return (
    <TableWrapper>
      <Table>
        {TableHeader}
        <tbody style={{ verticalAlign: 'top' }}>
          {!!requirements
            ? requirements.map(({ id, name }) => (
                <TableRow key={id}>
                  <TableBodyHeader>{t(name)}</TableBodyHeader>
                  {Object.values(tableData).map((proposal, index) => {
                    const cellValue = getProposalValue(proposal, id)
                    const cell = Array.isArray(cellValue) ? (
                      <TableCell key={index}>
                        {cellValue.map((value, cellValueIndex) => {
                          return (
                            <div
                              style={{
                                marginBottom: 10
                              }}
                            >
                              {cellValueIndex > 0 ? (
                                <span style={{ display: 'inline-block' }}>
                                  -
                                </span>
                              ) : null}
                              {Object.entries(value).map(
                                ([name, value], index) => {
                                  if (typeof value !== 'object') {
                                    return (
                                      <p
                                        key={index}
                                        style={{
                                          marginTop: 0,
                                          marginBottom: 5
                                        }}
                                      >
                                        {name}: {value}
                                      </p>
                                    )
                                  }
                                  return (
                                    <p
                                      key={index}
                                      style={{ marginTop: 0, marginBottom: 10 }}
                                    >
                                      {name} {Object.keys(value)[0]}:{' '}
                                      {Object.values(value)[0]}
                                    </p>
                                  )
                                }
                              )}
                            </div>
                          )
                        })}
                      </TableCell>
                    ) : (
                      <TableCell key={index}>
                        {id === 'f2ada4dc-0c6e-41c1-975a-bfedbe4f8457' &&
                        cellValue
                          ? 'Tekijät.pdf'
                          : cellValue}
                      </TableCell>
                    )
                    return cell
                  })}
                </TableRow>
              ))
            : null}
        </tbody>
      </Table>
    </TableWrapper>
  )
}

const requirementsValue = (id, value) => {
  let formattedValue = value

  if (_.isEmpty(value)) {
    return null
  }

  if (Object.keys(value).includes('fileName')) {
    return (formattedValue = !_.isEmpty(value) ? value.fileName : '')
  }

  if (id.includes('table-')) {
    return (formattedValue = !_.isEmpty(value)
      ? Object.entries(value).map(([name, data]) =>
          !!data ? { [name]: data } : {}
        )
      : '')
  }

  return formattedValue
}

const createString = (array, glue = ': ', separator = ', ') =>
  array
    .map(object =>
      Object.entries(object).map(([key, value]) => {
        return [key, value].join(glue)
      })
    )
    .join(separator)

function mergeObjects(array, key) {
  const res = array.reduce((acc, obj) => {
    let found = false
    for (let i = 0; i < acc.length; i++) {
      if (acc[i][key] === obj[key]) {
        found = true
        Object.assign(acc[i], obj)
      }
    }
    if (!found) {
      acc.push(obj)
    }
    return acc
  }, [])
  return res
}

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  user-select: text;

  h1 {
    margin-bottom: 0;
    font-size: 1.1rem;
  }
`

const TableWrapper = styled.div`
  overflow: auto;
  user-select: text;
`

const Table = styled.table`
  white-space: nowrap;
  margin: 0;
  border: none;
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed;
  /* width: 100%; */
`

const TableRow = styled.tr`
  border-bottom: 1px solid #d8e0e4;
`

const TableHead = styled.th`
  padding: 16px 25px 16px 25px;
  background-color: #d8e0e4;
  position: sticky;
  top: 0;
  min-width: 250px;
  /* z-index: 1; */

  &:first-child {
    min-width: 400px;
    text-align: left;
    position: sticky;
    left: 0;
    z-index: 2;
    /* padding-left: 0px; */
  }
`

const TableCell = styled.td`
  padding: 16px 25px 16px 25px;
  white-space: break-spaces;
  hyphens: auto;

  &:nth-child(odd) {
    background-color: #eff2f3;
  }
`

const TableBodyHeader = styled.th`
  position: sticky;
  left: 0;
  padding: 16px 25px 16px 25px;
  text-align: left;
  background-color: #fff;
  z-index: 1;
  white-space: break-spaces;
`

const ProposalNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Status = styled.div`
  max-width: max-content;
  margin-top: 10px;
  padding: 5px 10px;
  background-color: ${({ status }) =>
    status === 'keskeneräinen' ? 'red' : 'green'};
  color: #fff;
`

function FilterOptions({ handleFilter }) {
  const { t } = useTranslation('proposals')

  return (
    <Menu>
      <StyledMenuBUtton>
        <BiFilterAlt style={{ width: 18, height: 18, marginRight: 5 }} />
        <span>{t('Filters')}</span>
      </StyledMenuBUtton>
      <MenuList>
        <StyledMenuItem onSelect={() => handleFilter('all')}>
          {t('All')}
        </StyledMenuItem>
        <StyledMenuItem onSelect={() => handleFilter('keskeneräinen')}>
          {t('Incomplete')}
        </StyledMenuItem>
        <StyledMenuItem onSelect={() => handleFilter('valmis')}>
          {t('Complete')}
        </StyledMenuItem>
      </MenuList>
    </Menu>
  )
}

const StyledMenuBUtton = styled(MenuButton)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #d8e0e4;
  border-radius: 5px;
  padding: 8px 10px;
  font-weight: bold;
  color: #fff;
  background-color: #3f51b5;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #303e8e;
  }
`

const StyledMenuItem = styled(MenuItem)`
  &[data-selected] {
    color: #fff;
    background-color: #3f51b5;
  }
`

function ExportOptions({
  excelData,
  fileName,
  wscols,
  submissionDeadline,
  urls
}) {
  const [loading, setLoading] = React.useState(false)
  const { t } = useTranslation('proposals')

  return (
    <Menu>
      <StyledMenuBUtton>
        {loading ? (
          <Spinner />
        ) : (
          <MdOutlineDownloadForOffline
            style={{ width: 18, height: 18, marginRight: 5 }}
          />
        )}
        <span>{t('Export')}</span>
      </StyledMenuBUtton>
      <MenuList>
        <StyledMenuItem onSelect={() => {}}>
          <ExportToExcel
            excelData={excelData}
            fileName={fileName}
            wscols={wscols}
            submissionDeadline={submissionDeadline}
          />
        </StyledMenuItem>
        {/* <StyledMenuItem onSelect={() => {}}>
          <DownloadMaterials
            urls={urls}
            setLoading={setLoading}
            fileName={fileName}
          />
        </StyledMenuItem> */}
      </MenuList>
    </Menu>
  )
}

const Spinner = styled(FaSpinner)`
  width: 18px;
  height: 18px;
  margin-right: 5px;
  animation: spin 1s infinite linear;

  @keyframes spin {
    from {
      transform: scale(1) rotate(0deg);
    }
    to {
      transform: scale(1) rotate(360deg);
    }
  }
`
