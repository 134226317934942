import React from 'react';
import styled from 'styled-components';

export default function FlexWrapper({
  direction = 'row',
  justifyContent,
  alignItems,
  children,
}) {
  return (
    <Wrapper
      style={{
        '--direction': direction,
        '--justifyContent': justifyContent,
        '--alignItems': alignItems,
      }}
    >
      {children}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: var(--direction);
  justify-content: var(--justifyContent);
  align-items: var(--alignItems);
`;
