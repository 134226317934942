import React from 'react'
import { useTranslation } from 'react-i18next'

import ToggleSwitch from './components/ToggleSwitch'
import FlexWrapper from './components/FlexWrapper'
import useFirestore from './hooks/useFirestore'

export default function SubmitPageStatus({ status }) {
  const [isActive, setIsActive] = React.useState(status)
  const { updateDocument } = useFirestore('info')
  const { t } = useTranslation('proposals')

  const handleChangeStatus = () => {
    console.log('Update status')
    setIsActive(!isActive)
    updateDocument('general', { submitPageStatus: !isActive })
  }

  return (
    <FlexWrapper alignItems="center">
      <p style={{ minWidth: 150, marginRight: 24 }}>
        {t('Submit page status')}:{' '}
      </p>
      <FlexWrapper alignItems="center">
        <span>inactive</span>
        <div style={{ marginLeft: 10, marginRight: 10 }}>
          <ToggleSwitch
            itemId="submit-page-status"
            checked={status ? status : isActive}
            onChange={handleChangeStatus}
          />
        </div>
        <span>active</span>
      </FlexWrapper>
    </FlexWrapper>
  )
}
