import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { styled } from '@material-ui/styles';

import Select from '../../inputs/Select';
import { createSortCommentsBy } from '../../util/Helper';
import { addToDataBase } from '../../redux/actions/dataBase';
import { useTranslation } from 'react-i18next';

const SortBy = styled('div')(({ theme }) => ({
  color: theme.modeColors.proposalTitle,
}));

const SortComments = ({ profile, addToDataBase, userId }) => {
  const initialSortByValue = profile?.sortCommentsBy || 'date';
  const [value, setValue] = useState(initialSortByValue);
  const { t } = useTranslation('proposals');

  const options = [
    {
      label: t('Date'),
      value: 'date',
    },
    {
      label: t('Proposal'),
      value: 'proposal',
    },
  ];

  const handleChange = event => {
    setValue(event.target.value);
    // Save to database user choice sort by value
    createSortCommentsBy(event.target.value, userId, addToDataBase);
  };

  return (
    <div id="sort-comments" className="d-flex align-items-center">
      <SortBy className="sm pr-1">{t('Sort by')}:</SortBy>
      <div>
        <Select onChange={handleChange} value={value} options={options} fixedWidth />
      </div>
    </div>
  );
};

SortComments.propTypes = {
  addToDataBase: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  userId: PropTypes.string.isRequired,
};

const mapStateToProps = state => {
  return {
    profile: state.firebase.profile,
    userId: state.firebase.auth.uid,
  };
};

export default connect(mapStateToProps, { addToDataBase })(SortComments);
