import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import Box from '@material-ui/core/Box';

function ListItem() {
  return (
    <Box
      borderBottom="1px solid #ccced0"
      display="flex"
      flexDirection="row"
      alignItems="center"
      height="3.5rem"
    >
      <Box mr={2}>
        <Skeleton variant="rect" width="16px" height="16px" />
      </Box>
      <Box mr={2}>
        <Skeleton variant="rect" width="30px" height="30px" />
      </Box>
      <Box mr={3}>
        <Skeleton variant="rect" width="100px" height="16px" />
      </Box>
      <Box mr={3}>
        <Skeleton variant="rect" width="80px" height="16px" />
      </Box>
    </Box>
  );
}

export default function LoadingListView() {
  return Array.from(new Array(73)).map((element, index) => <ListItem key={index} />);
}
