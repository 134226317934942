import * as React from 'react';

import { Box } from '@material-ui/core';
import { AccountBox, FiberNew } from '@material-ui/icons';
import { connect } from 'react-redux';

import CommentSelect from '../../proposals/comments-view/CommentSelect';

function Comment({ comment, profile }) {
  const { name, createdAt, content, isNew } = comment;

  const getTimeDiffInSeconds = startDate => {
    const endDate = new Date();
    const timeDiff = Math.abs(endDate.getTime() - startDate.getTime());
    const timeDiffInSeconds = Math.ceil(timeDiff / 1000);

    return timeDiffInSeconds;
  };

  const getTimeDifference = () => {
    const startDate = new Date(createdAt.toDate());
    const timeDiffInSeconds = getTimeDiffInSeconds(startDate);
    const days = Math.floor(timeDiffInSeconds / (3600 * 24));
    const hours = Math.floor((timeDiffInSeconds % (3600 * 24)) / 3600);
    const minutes = Math.floor((timeDiffInSeconds % 3600) / 60);
    const seconds = Math.floor(timeDiffInSeconds % 60);

    if (days > 0) {
      return days + 'd';
    }
    if (hours > 0) {
      return hours + 'h';
    }
    if (minutes > 0) {
      return minutes + 'm';
    }
    return seconds + 's';
  };

  return (
    <Box mb="15px">
      <div style={{ padding: '5px 10px', backgroundColor: '#f2f3f5' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: 10,
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-end',
            }}
          >
            <AccountBox color="primary" style={{ width: 30, height: 30 }} />
            <h3 style={{ marginBottom: 0, marginLeft: 2, fontSize: 14 }}>{name}</h3>
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            {isNew ? (
              <FiberNew color="secondary" style={{ marginRight: 10, color: '#f93838' }} />
            ) : null}
            <p style={{ fontSize: 12, color: '#545454' }}>{getTimeDifference()}</p>
            {profile.role !== 'judge' ? <CommentSelect comment={comment} /> : null}
          </div>
        </div>
        <div style={{ fontSize: 14 }}>{content}</div>
      </div>
    </Box>
  );
}

const mapStateToProps = state => {
  return {
    profile: state.firebase.profile,
  };
};

export default connect(mapStateToProps)(Comment);
