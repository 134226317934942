import React from 'react';

import TextField from '../inputs/Text';
import CustomRadio from '../inputs/CustomRadio';
import Button from '../inputs/Button';
import { useTranslation } from 'react-i18next';

const CreateClass = ({ nameOfClass, handleChange, chosenColor, createClass }) => {
  const { t } = useTranslation('proposals');
  const proposalClassColors = [
    '#999da0',
    '#61be65',
    '#f3e200',
    '#ffa632',
    '#ff4c4c',
    '#3f51b5',
    '#61a8be',
    '#bc61be',
    '#dedede',
    '#a74c32',
    '#b6a55c',
    '#ffa8ad',
  ];

  return (
    <div className="py-3">
      <h5 className="mb-2">{t('Create a new class')}</h5>
      <div>
        <TextField
          value={nameOfClass}
          onChange={handleChange}
          name="nameOfClass"
          placeholder={t('Text')}
          size="md"
        />
        <div className="d-flex align-items-center justify-content-between mt-2">
          <div>
            <div className="sm mb-1">{t('Choose colour')}</div>
            {proposalClassColors.map(color => (
              <CustomRadio
                checked={color === chosenColor}
                onChange={handleChange}
                key={color}
                name="chosenColor"
                color={color}
                value={color}
              />
            ))}
          </div>

          <Button
            style={{ padding: '9px 12px 7px' }}
            size="md"
            variant="outlined"
            color="primary"
            onClick={createClass}
          >
            {t('Add')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CreateClass;
