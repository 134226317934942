import React from 'react';
import styled from 'styled-components';

export default function Icon({
  width = '45px',
  height = '45px',
  mr = '16px',
  ml = '0',
  children,
}) {
  return (
    <Wrapper style={{ '--width': width, '--height': height, '--mr': mr, '--ml': ml }}>
      {children}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: inline-block;
  border-radius: 50px;
  width: var(--width);
  height: var(--height);
  margin-right: var(--mr);
  margin-left: var(--ml);
  padding: 10px;
  background-color: transparent;
  cursor: pointer;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }

  svg {
    width: 100%;
    height: 100%;
  }
`;
