import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { email } from 'redux-form-validators';
import { withStyles } from '@material-ui/core/styles';
import { signInWithEmailLink } from '../redux/actions/authActions';
import Button from '../inputs/Button';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import TextField from '../inputs/Text';

const styles = theme => ({
  dialogPaper: {
    borderRadius: 0,
  },
  dialogTitle: {
    borderBottom: '1px solid #e0e2e4',
    margin: 0,
    padding: '20px 16px 18px',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: '#353c43',
  },
});

class LoginWithLink extends React.Component {
  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };

  handleLogin = values => {
    const { email } = values;
    this.props.signInWithEmailLink(email, window.location.href);
  };

  render() {
    const { close, isOpen, classes } = this.props;
    return (
      <Dialog
        aria-labelledby="customized-dialog-title"
        open={isOpen}
        maxWidth="md"
        classes={{ paper: classes.dialogPaper }}
      >
        <DialogTitle disableTypography className={classes.dialogTitle}>
          <h3 className="mb-0">Confirm the email</h3>
          <IconButton aria-label="Close" className={classes.closeButton} onClick={close}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </DialogTitle>
        <form onSubmit={this.props.handleSubmit(this.handleLogin)}>
          <div className="px-3 pt-2 pb-0">
            <div className="sm mb-2 pb-1">Enter your email</div>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={12}>
                <div className="sm">Email</div>
              </Grid>
              <Grid item xs={12}>
                <Field
                  component={TextField}
                  id="email"
                  name="email"
                  onChange={this.handleChange}
                  size="md"
                  placeholder="Input"
                  validate={[email()]}
                  validation
                />
              </Grid>
            </Grid>
          </div>
          <div className="d-flex align-items-center justify-content-end pt-1 m-3">
            <Button size="md" variant="outlined" onClick={close} color="primary">
              Cancel
            </Button>
            <Button
              style={{ marginLeft: '8px' }}
              type="submit"
              size="md"
              variant="contained"
              color="primary"
            >
              Login
            </Button>
          </div>
        </form>
      </Dialog>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    signInWithEmailLink: (email, location) =>
      dispatch(signInWithEmailLink(email, location)),
  };
};

const LoginWithLinkForm = reduxForm({
  form: 'LoginWithLinkForm', // a unique identifier for this form
})(withStyles(styles)(LoginWithLink));

export default connect(null, mapDispatchToProps)(withStyles(styles)(LoginWithLinkForm));
