import React from 'react';
import { Grid } from '@material-ui/core';

import CommentCard from './CommentCard';

export default function GroupCommentsByDate({ sortedComments, classes }) {
  return sortedComments.map(({ date, comments }, index) => {
    return (
      <div key={`${date}-${index}`} className={classes.wrapper}>
        <h2 className={classes.groupTitle}>{date}</h2>
        <Grid container spacing={3}>
          {comments.map((comment, index) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              key={index}
              style={{ boxSizing: 'border-box', marginBottom: '40px' }}
            >
              <CommentCard comment={comment} />
            </Grid>
          ))}
        </Grid>
      </div>
    );
  });
}
