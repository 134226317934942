import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import { compose } from 'redux'
import { firestoreConnect, isLoaded } from 'react-redux-firebase'
import { connect } from 'react-redux'
import { withSnackbar } from 'notistack'
import moment from 'moment'
import cookie from 'react-cookies'
import queryString from 'query-string'

import { withStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'

import { addToDataBase, deleteFromDataBase } from '../redux/actions/dataBase'
import { setViewMode, setActiveView } from '../redux/actions/proposalsActions'
import Zoom from './Zoom'
import * as Helper from '../util/Helper.js'
import Element from './Element'
import Elements from './Elements'
import Sidebar from './Sidebar'
// import Spinner from '../spinner/Spinner';
import ViewMode from '../proposals/ViewMode'
import ManageRatings from './ManageRatings'
import MultipleLinesChart from './MultipleLinesChart'
import { rating_fields } from './rating_fields'
import CollaborativeReview from './CollaborativeReview'
import ThreeDModel from './3dModel'
import { withTranslation } from 'react-i18next'

const styles = theme => ({
  elements: {
    flex: 1,
    padding: '24px'
  }
})

class SingleProposal extends Component {
  state = {
    proposal: null,
    zoom: 2,
    isModalOpen: false,
    singleElement: null,
    editClassesModal: false,
    date: null,
    expand: true,
    fullElement: false,
    isRatingModalOpen: false,
    isAllRatingsModalOpen: false,
    rateSaved: false,
    view: 'elements'
  }

  zoomIn = () => {
    const { zoom } = this.state
    if (zoom < 6) {
      this.setState({ zoom: zoom + 1 })
    }
  }

  zoomOut = () => {
    const { zoom } = this.state
    if (zoom > 1) {
      this.setState({ zoom: zoom - 1 })
    }
  }

  openRatingModal = () => this.setState({ isRatingModalOpen: true })
  openAllRatingsModal = () => this.setState({ isAllRatingsModalOpen: true })

  closeRatingModal = () => this.setState({ isRatingModalOpen: false })
  closeAllRatingsModal = () => this.setState({ isAllRatingsModalOpen: false })

  componentDidMount() {
    const readOnly = process.env.REACT_APP_READ_ONLY
    if (!readOnly) {
      const { addToDataBase, profile, userId, match } = this.props
      const savedProposal = _.get(profile, `proposals.${match.params.id}`, {})
      const currentDate = moment().format('lll')
      if (savedProposal.date) {
        addToDataBase('users', userId, {
          proposals: { [match.params.id]: { lastSeen: savedProposal.date } }
        })
        addToDataBase('users', userId, {
          proposals: { [match.params.id]: { date: currentDate } }
        })
      } else {
        addToDataBase('users', userId, {
          proposals: { [match.params.id]: { lastSeen: currentDate } }
        })
        addToDataBase('users', userId, {
          proposals: { [match.params.id]: { date: currentDate } }
        })
      }
    }

    const proposal = this.getProposal()

    proposal && this.setState({ proposal }, this.updateViewModeState)
  }

  componentDidUpdate(prevProps) {
    const {
      location,
      proposals: { cookies, data }
    } = this.props
    const {
      location: prevLocation,
      proposals: { cookies: prevCookies }
    } = prevProps
    const proposal = this.getProposal()

    if (!_.isEmpty(data) && !this.state.proposal && !this.state.singleElement) {
      // Fix elements of undefined when refresh from SingleProposal or SingleElement
      this.setState({ proposal }, this.updateViewModeState)
    }
    if (location.search !== prevLocation.search) {
      this.updateViewModeState()
    }
    if (location.pathname !== prevLocation.pathname) {
      this.setState({ proposal }, this.updateViewModeState)
    }
    if (cookies) {
      const cookiesQuery = queryString.parse(cookies.replace(/; /g, '&'))
      const prevCookiesQuery = queryString.parse(
        prevCookies.replace(/; /g, '&')
      )
      if (
        proposal &&
        cookiesQuery.JUG_MODE === 'full' &&
        window.name.includes('page') &&
        !this.state.fullElement
      ) {
        setTimeout(() => this.fullElementMode(proposal), 500)
      }
      if (
        cookiesQuery.j_count !== prevCookiesQuery.j_count &&
        proposal &&
        cookiesQuery.JUG_MODE === 'full' &&
        window.name.includes('page')
      ) {
        setTimeout(
          () => this.fullElementMode(proposal, parseInt(cookiesQuery.j_count)),
          500
        )
      }
      if (
        cookiesQuery.j_proposal !== prevCookiesQuery.j_proposal &&
        proposal &&
        cookiesQuery.JUG_MODE === 'full' &&
        window.name.includes('page')
      ) {
        this.setState({ fullElement: false })
        this.props.history.push(`/proposal/${cookiesQuery.j_proposal}`)
      }
    }
  }

  fullElementMode = (proposal, count = 0) => {
    if (this.props.viewMode === 'normal') return
    const keys = Object.keys(proposal.elements)
    const pageName = window.name

    this.setState({ fullElement: true })
    if (count >= 0) {
      this.props.history.push({
        search: null
      })
    }

    if (pageName === 'page 1') {
      this.openSingleElement(keys[count], keys.indexOf(keys[count]))
    }
    if (pageName === 'page 2') {
      this.openSingleElement(keys[count + 1], keys.indexOf(keys[count + 1]))
    }
    if (pageName === 'page 3') {
      this.openSingleElement(keys[count + 2], keys.indexOf(keys[count + 2]))
    }
  }

  openSingleElement = (elementKey, index) => {
    const { history } = this.props
    const query = queryString.parse(this.props.location.search)

    if (elementKey && !query.view_mode) {
      history.push({
        search: `view_mode=true&start_key=${elementKey}&start_index=${index}`
      })
    }
  }

  getStartKey = (start_key, start_index) => {
    const { proposal } = this.state
    if (!proposal) return
    const query = queryString.parse(this.props.location.search)
    let historyObj = { search: '' }

    if (start_key && Object.keys(proposal.elements).includes(start_key)) {
      return start_key
    } else if (start_index < Object.keys(proposal.elements).length) {
      return Object.keys(proposal.elements)[start_index]
    }
    if (query.view_mode) {
      historyObj.search = 'view_mode=true&start_index=0'
    }
    this.props.history.push(historyObj)

    return Object.keys(proposal.elements)[0]
  }

  updateViewModeState = () => {
    const { proposal } = this.state
    const query = queryString.parse(this.props.location.search)
    const startKey = this.getStartKey(query.start_key, query.start_index)

    if (query.view_mode) {
      this.setState({
        isModalOpen: true,
        singleElement: proposal.elements[startKey],
        id: startKey
      })
    } else {
      this.setState({
        isModalOpen: false
      })
    }
  }

  closeModal = () => {
    const { history, viewMode, setViewMode } = this.props

    // Close full view mode modal
    if (viewMode === 'full') setViewMode('normal')
    cookie.remove('JUG_MODE', { path: '/' })
    this.setState({ isModalOpen: false, fullElement: false })

    history.push({
      search: ''
    })
  }

  closeEditCalssesModal = () => this.setState({ editClassesModal: false })
  OpenEditCalssesModal = () => this.setState({ editClassesModal: true })

  getProposal = () => {
    const { proposals, match } = this.props

    // Redirect user to homepage when single proposal is not found:
    // 1. Missing proposal
    // 2. User refreshes single proposal page when viewing proposal in phase 2
    if (proposals?.data) {
      if (!proposals.data[match.params.id]) {
        return this.props.history.push('/')
      }
      return proposals.data[match.params.id]
    }
  }

  toggleSidebar = () => {
    this.setState({ expand: !this.state.expand })
  }

  toggleView = viewType => {
    this.props.setActiveView(viewType)
  }

  copyAveragesRateToPublicRate = (averageRate, proposalId) => {
    Helper.updatePublicRate(averageRate, proposalId, this.props, () =>
      this.setState({ rateSaved: true })
    )
  }

  saveRates = (rate, proposalId) => {
    if (this.props.profile.role !== 'judge') {
      Helper.updatePublicRate(rate, proposalId, this.props, () =>
        this.setState({ rateSaved: true })
      )
    } else {
      Helper.updatePrivateRate(rate, proposalId, this.props, () =>
        this.setState({ rateSaved: true })
      )
    }
  }

  unsetRateSaved = () => {
    this.setState({ rateSaved: false })
  }

  render() {
    const readOnly = process.env.REACT_APP_READ_ONLY
    const {
      classes,
      proposals,
      PublicClasses,
      profile,
      match,
      proposalsData,
      selectedPhase,
      users,
      userId,
      t
    } = this.props
    const {
      expand,
      proposal,
      fullElement,
      isAllRatingsModalOpen,
      rateSaved,
      view
    } = this.state
    const proposalId = match.params.id
    const proposalClass = Helper.getProposalClass(
      proposalId,
      proposalsData,
      PublicClasses
    )
    const proposalPrivateRate = Helper.getProposalPrivateRate(
      profile,
      proposalId
    )
    const proposalPublicRate =
      isLoaded(proposalsData) &&
      Helper.getProposalPublicRate(proposalsData, proposalId)
    const proposalAllRate = Helper.getProposalAllRate(users, proposalId)
    const averageRate = proposalAllRate.rate ? proposalAllRate.rate : {}

    if (proposals.loading || _.isEmpty(proposals.data) || _.isEmpty(proposal)) {
      return <div />
    }

    return (
      proposal && (
        <>
          <div className="d-flex">
            {/*all Elements*/}
            <div className={classes.elements}>
              <div className="d-flex justify-content-end align-items-center px-3">
                <Button
                  size="medium"
                  variant="outlined"
                  onClick={() =>
                    view === 'elements' || view === '3d-model'
                      ? this.setState({ view: 'editor' })
                      : this.setState({ view: 'elements' })
                  }
                  color="primary"
                  className="mx-2"
                >
                  {view !== 'editor'
                    ? t('Open collaborative review')
                    : t('Close collaborative review')}
                </Button>
                {/* <Button
                  size="medium"
                  variant="contained"
                  onClick={() =>
                    view === 'elements' || view === 'editor'
                      ? this.setState({ view: '3d-model' })
                      : this.setState({ view: 'elements' })
                  }
                  color="primary"
                  className="mx-2"
                >
                  {view === 'elements' || view === 'editor'
                    ? 'Open 3D model'
                    : 'Close 3D model'}
                </Button> */}
                <Link
                  to="/"
                  className="d-flex justify-content-end align-items-center"
                  style={{
                    cursor: 'pointer',
                    textDecoration: 'none',
                    color: '#000'
                  }}
                >
                  <ViewMode
                    toggleView={this.toggleView}
                    activeView=""
                    isSecondPhase={selectedPhase === 'phase-2'}
                  />
                </Link>
                {view === 'elements' ? (
                  <Zoom
                    zoomIn={this.zoomIn}
                    zoomOut={this.zoomOut}
                    zoomLevel={this.state.zoom}
                  />
                ) : null}
              </div>
              {view === 'elements' && (
                <div className="p-2 mb-4">
                  <Elements
                    single
                    openSingleElement={this.openSingleElement}
                    zoom={this.state.zoom}
                    elements={proposal.elements}
                    proposalId={match.params.id}
                  />
                </div>
              )}
              {view === 'editor' && (
                <CollaborativeReview
                  proposalId={match.params.id}
                  userId={userId}
                  profile={profile}
                />
              )}
              {view === '3d-model' && (
                <ThreeDModel proposalId={match.params.id} />
              )}
            </div>

            <Sidebar
              expand={expand}
              proposal={proposal}
              profile={profile}
              readOnly={readOnly}
              proposalClass={proposalClass}
              proposalId={proposalId}
              toggleSidebar={this.toggleSidebar}
              OpenEditCalssesModal={this.OpenEditCalssesModal}
              openRatingModal={this.openRatingModal}
              openAllRatingsModal={this.openAllRatingsModal}
            />

            {/* The Element Modal */}
            <Element
              id={this.state.id}
              element={this.state.singleElement}
              elements={proposal.elements}
              proposalName={proposal.number}
              close={this.closeModal}
              isOpen={this.state.isModalOpen}
              expand={!expand}
              history={this.props.history}
              noSlider={fullElement}
              proposalsData={this.props.proposalsData}
              PublicClasses={this.props.PublicClasses}
            />
          </div>

          {/* Rating modal */}
          {isLoaded(proposalsData) && isLoaded(profile) && (
            <ManageRatings
              title={
                profile.role !== 'judge' ? t('Jury rating setup') : 'My rating'
              }
              close={this.closeRatingModal}
              isOpen={this.state.isRatingModalOpen}
              proposal={proposal}
              fields={rating_fields}
              onSave={this.saveRates}
              proposalPrivateRate={
                profile.role !== 'judge'
                  ? proposalPublicRate
                  : proposalPrivateRate
              }
              secretary={profile.role !== 'judge'}
              proposalAverageRate={proposalAllRate.rate}
              copyAveragesRate={() =>
                this.copyAveragesRateToPublicRate(averageRate, proposalId)
              }
              match={match}
              rateSaved={rateSaved}
              unsetRateSaved={this.unsetRateSaved}
            />
          )}

          {/* All Ratings Modal */}
          {profile.role !== 'judge' && isAllRatingsModalOpen && (
            <MultipleLinesChart
              close={this.closeAllRatingsModal}
              isOpen={isAllRatingsModalOpen}
              allRates={proposalAllRate}
              proposal={proposal}
            />
          )}
        </>
      )
    )
  }
}

const mapStateToProps = state => {
  return {
    userId: state.firebase.auth.uid,
    proposals: state.proposals,
    profile: state.firebase.profile,
    PublicClasses: state.firestore.ordered.PublicClasses,
    proposalsData: state.firestore.ordered.proposals,
    viewMode: state.proposals.viewMode,
    selectedPhase: state.proposals.selectedPhase,
    users: state.firestore.ordered.users
  }
}

const mapDispatchToProps = dispatch => {
  return {
    addToDataBase: (coll, doc, data) =>
      dispatch(addToDataBase(coll, doc, data)),
    deleteFromDataBase: (coll, doc) => dispatch(deleteFromDataBase(coll, doc)),
    setViewMode: viewMode => dispatch(setViewMode(viewMode)),
    setActiveView: activeView => dispatch(setActiveView(activeView))
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect([
    { collection: 'PublicClasses', orderBy: ['position', 'asc'] },
    { collection: 'proposals' },
    { collection: 'users' }
  ])
)(
  withSnackbar(withTranslation('proposals')(withStyles(styles)(SingleProposal)))
)
