import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { setNewPassword } from '../redux/actions/authActions';
import { required, length, confirmation } from 'redux-form-validators';
import Button from '../inputs/Button';
import TextField from '../inputs/Text';

class SetPassword extends Component {
  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };

  handleLogin = values => {
    // console.log('values', values);
    this.props.setNewPassword(values.password);
  };

  closeModal = () => {
    this.setState({ isModalOpen: false });
  };

  handleCloseSnackbar = () => {
    this.setState({ openSnackbar: false });
    this.props.resetAuthEroor();
  };

  render() {
    const { pristine, submitting } = this.props;
    return (
      <form
        onSubmit={this.props.handleSubmit(this.handleLogin)}
        className="d-flex flex-column align-items-center justify-content-center fit-height"
      >
        <h1>Set new password</h1>
        <Field
          component={TextField}
          className="mb-2 minWidth"
          id="password"
          name="password"
          type="password"
          size="lg"
          label="Password"
          validate={[required(), length({ min: 8 })]}
          validation
        />

        <Field
          component={TextField}
          stye={{ minWidth: 800 }}
          className="mb-2 minWidth"
          id="confirmPassword"
          name="confirmPassword"
          type="password"
          size="lg"
          label="Confirm Password"
          validate={confirmation({ field: 'password', fieldLabel: 'Password' })}
          validation
        />
        <div className="d-flex justify-content-end minWidth p-0">
          <Button
            style={{ marginLeft: '16px' }}
            size="md"
            variant="contained"
            color="primary"
            type="submit"
            disabled={pristine || submitting}
          >
            Save
          </Button>
        </div>
      </form>
    );
  }
}

const mapStateToProps = state => {
  return {
    profile: state.firebase.profile,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setNewPassword: newPass => dispatch(setNewPassword(newPass)),
  };
};

const SetPasswordForm = reduxForm({
  form: 'SetPasswordForm', // a unique identifier for this form
})(SetPassword);

export default connect(mapStateToProps, mapDispatchToProps)(SetPasswordForm);
