import * as React from 'react'
import styled from 'styled-components'
import { v4 as uuidv4 } from 'uuid'
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors
} from '@dnd-kit/core'
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy
} from '@dnd-kit/sortable'

// import Button from './components/Button';
import AddSubmissionItem from './AddSubmissionItem'
import SubmissionItem from './SubmissionItem'
import useFirestore from './hooks/useFirestore'
import useCollection from './hooks/useCollection'

const initialItems = [
  {
    id: 'pseudonym',
    displayType: 'Proposal pseudonym',
    unit: 'Char',
    name: 'Pseudonym (must be anonymous)',
    limit: '75',
    type: 'text',
    required: true,
    description: ''
  },
  {
    id: 'board',
    displayType: 'Board pdf file',
    unit: 'Pages',
    name: '',
    limit: '6',
    type: 'file',
    fileType: 'pdf',
    required: true,
    description: ''
  },
  {
    id: `table-${uuidv4()}`,
    displayType: 'Data entry table',
    unit: '',
    name: '',
    limit: '',
    type: 'table',
    data: [],
    required: true,
    description: ''
  }
]

export default function SubmissionItems() {
  const { updateDocument, response } = useFirestore('info')
  const { documents } = useCollection('info')
  const [items, setItems] = React.useState(initialItems)
  const hasPseudonymItem = items.some(item => item.id === 'pseudonym')

  const handleAddItem = async item => {
    const itemId = item.type === 'table' ? `table-${uuidv4()}` : uuidv4()
    const newItems = [...items, { id: itemId, ...item }]

    updateDocument('submission', { items: newItems })
    setItems(newItems)
  }

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  )

  function handleDragEnd(event) {
    const { active, over } = event

    if (active.id !== over.id) {
      const oldIndex = items.findIndex(item => item.id === active.id)
      const newIndex = items.findIndex(item => item.id === over.id)
      const newItems = arrayMove(items, oldIndex, newIndex)

      setItems(newItems)
      updateDocument('submission', { items: newItems })
    }
  }

  React.useEffect(() => {
    if (!!documents && documents?.submission?.items.length) {
      setItems(documents?.submission?.items)
    } else {
      setItems(initialItems)
    }
  }, [documents])

  return (
    <div>
      <div>
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
        >
          <SortableContext items={items} strategy={verticalListSortingStrategy}>
            {items.map(item => (
              <SubmissionItem
                key={item.id}
                item={item}
                items={items}
                setItems={setItems}
              />
            ))}
          </SortableContext>
        </DndContext>

        <Wrapper>
          <div style={{ marginRight: 12 }}>
            <AddSubmissionItem
              onSelectAddItem={handleAddItem}
              hasPseudonymItem={hasPseudonymItem}
            />
          </div>
          {/* <Button type="submit" onClick={handleSave}>
          Save
        </Button> */}
        </Wrapper>

        {response.error ? (
          <div style={{ color: 'red' }}>{response.error}</div>
        ) : null}
      </div>
    </div>
  )
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 3rem;
  margin-bottom: 1rem;
`
