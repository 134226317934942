import cityLogo from './assets/icons/espoo-seeklogo.com.svg'

export const logo = {
  src: cityLogo,
  alt: 'Espoo-logo'
}

export const competitionName = 'Leppävaaran Keskus'

export const infoTableHeaders = [
  { header: 'Criteria 1', accessor: 'customer_service_spaces' },
  { header: 'Criteria 2', accessor: 'restaurant' },
  { header: 'Criteria 3', accessor: 'exhibition_spaces' },
  {
    header: 'Criteria 4',
    accessor: 'black_box_multifunctional_hall'
  },
  { header: 'Criteria 5', accessor: 'learning_center' }
]

export const settingsTabs = [
  { id: 'settings-users', label: 'Users', value: 'users' },
  { id: 'settings-submit-setup', label: 'Submit setup', value: 'submit-setup' },
  { id: 'settings-tab-setup', label: 'Tab setup', value: 'tab-setup' },
  { id: 'settings-theme', label: 'Theme', value: 'theme' }
]
