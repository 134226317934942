const initState = {
  hearingData: null,
};

const kerrokantasiReducer = (state = initState, action) => {
  switch (action.type) {
    case 'FETCH_KERROKANTASI_LOADING':
      return { ...state, loading: true };
    case 'FETCH_KERROKANTASI_SUCCESS':
      return {
        ...state,
        err: false,
        loading: false,
        hearingData: action.payload,
      };
    case 'FETCH_KERROKANTASI_ERROR':
      return {
        ...state,
        err: action.payload,
      };
    default:
      return state;
  }
};

export default kerrokantasiReducer;
