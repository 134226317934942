import React from 'react'
import styled from 'styled-components'
import { HiOutlinePlus } from 'react-icons/hi'
// import { positionMatchWidth } from '@reach/popover';
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  MenuPopover
} from '@reach/menu-button'
import '@reach/menu-button/styles.css'

const itemsToAdd = [
  {
    id: 'pseudonym',
    displayType: 'Proposal pseudonym',
    unit: 'Char',
    name: 'Pseudonym (must be anonymous)',
    limit: '75',
    type: 'text',
    required: true,
    description: ''
  },
  {
    displayType: 'Data entry table',
    unit: '',
    name: '',
    limit: '',
    type: 'table',
    data: [],
    required: true,
    description: ''
  },
  {
    displayType: 'Image jpg file',
    unit: 'MB',
    name: '',
    limit: '',
    type: 'file',
    fileType: 'jpg',
    required: true,
    description: ''
  },
  {
    displayType: 'Description pdf file',
    unit: 'MB',
    name: 'Description',
    limit: '',
    type: 'file',
    fileType: 'pdf',
    required: true,
    description: ''
  },
  {
    displayType: 'Text area large',
    unit: 'char',
    name: '',
    limit: '',
    type: 'textarea',
    required: true,
    description: ''
  },
  {
    displayType: 'Text single row',
    unit: 'char',
    name: '',
    limit: '',
    type: 'text',
    required: true,
    description: ''
  },
  {
    displayType: 'Bim IFC file',
    unit: 'MB',
    name: '',
    limit: '',
    type: 'file',
    fileType: 'ifc',
    required: true,
    description: ''
  },
  {
    displayType: 'Video mp4 file',
    unit: 'SEC',
    name: '',
    limit: '',
    type: 'file',
    fileType: 'mp4',
    required: true,
    description: ''
  },
  {
    displayType: '3D model submission',
    unit: 'MB',
    name: '',
    limit: '',
    type: 'file',
    fileType: 'obj',
    required: true,
    description: ''
  },
  {
    displayType: '3D model for xD Twin',
    unit: 'MB',
    name: '',
    limit: '',
    type: 'file',
    fileType: 'ifc',
    required: true,
    description: ''
  },
  {
    displayType: 'Custom File Format',
    unit: 'MB',
    name: '',
    limit: '',
    type: 'file',
    fileType: '',
    required: true,
    description: ''
  }
]

export default function AddSubmissionItem({
  onSelectAddItem,
  hasPseudonymItem
}) {
  const [items, setItems] = React.useState([])

  React.useEffect(() => {
    if (hasPseudonymItem) {
      const newItems = itemsToAdd.filter(item => item.id !== 'pseudonym')
      setItems(newItems)
    } else {
      setItems(itemsToAdd)
    }
  }, [hasPseudonymItem])

  return (
    <Menu>
      <StyledMenuButton>
        <HiOutlinePlus />
        <div style={{ marginTop: 3 }}>Add item</div>
      </StyledMenuButton>
      <StyledMenuPopover>
        <StyledMenuItems>
          {items.map((item, index) => (
            <StyledMenuItem key={index} onSelect={() => onSelectAddItem(item)}>
              {item.displayType}
            </StyledMenuItem>
          ))}
        </StyledMenuItems>
      </StyledMenuPopover>
    </Menu>
  )
}

const StyledMenuButton = styled(MenuButton)`
  border: 1px solid #3f51b5;
  display: flex;
  align-items: center;
  padding: 8px 15px;
  text-transform: uppercase;
  font-size: 1rem;
  background-color: #3f51b5;
  color: #fff;
  cursor: pointer;
  transition: all 0.2s ease;

  svg {
    width: 15px;
    height: 15px;
    margin-right: 10px;
  }
`

const StyledMenuPopover = styled(MenuPopover)`
  position: relative;
  z-index: 3;
`

const StyledMenuItems = styled(MenuItems)`
  border: 1px solid #f1f1f4;
  text-align: center;
`

const StyledMenuItem = styled(MenuItem)`
  margin: 5px 0;
  text-transform: uppercase;

  &:hover {
    background-color: #3f51b5;
  }
`
