import React, { useState } from 'react'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'

import { withStyles } from '@material-ui/core/styles'
import SettingsIcon from '@material-ui/icons/Settings'
import Tooltip from '@material-ui/core/Tooltip'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'

import ManageClasses from './ManageClasses'
import ElementTabs from './ElementTabs'
import Button from '../inputs/Button'
import TotalLikes from '../likes/TotalLikes'
import TotalComments from './Comments/TotalComments'

const styles = theme => ({
  sideNav: {
    position: 'sticky',
    top: props => (props.withoutHead ? 90 : 60),
    width: props => (props.expand ? 264 : 40),
    padding: props => (props.expand ? 16 : '16px 0'),
    minHeight: props =>
      props.withoutHead ? 'calc(100vh - 90px)' : 'calc(100vh - 60px)',
    borderLeft: '1px solid #e0e2e4',
    boxSizing: 'border-box',
    backgroundColor: theme.modeColors.singleProposalSideNavBg,
    boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.2)',
    overflow: 'auto',
    zIndex: 1001,
    color: theme.modeColors.singleProposalSideNavText
  },
  proposalTitle: {
    fontSize: '2.5rem',
    fontWeight: 700,
    paddingBottom: 4,
    borderBottom: '1px solid #ccced0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  textModeColors: {
    color: theme.modeColors.singleProposalSideNavText
  }
})

const Sidebar = ({
  expand,
  proposal,
  proposalClass,
  profile,
  readOnly,
  classes,
  proposalId,
  toggleSidebar,
  withoutHead,
  openRatingModal,
  openAllRatingsModal
}) => {
  const [classModal, setClassModal] = useState(false)
  const lastSeen = _.get(profile, `proposals.${proposalId}.lastSeen`, null)
  const { t } = useTranslation('proposals')
  return (
    <div className={classes.sideNav}>
      {/* Proposal Number */}
      {!withoutHead && (
        <div className={classes.proposalTitle}>
          {expand && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                flex: '100%'
              }}
            >
              <span>{proposal.number}</span>
              <div style={{ display: 'flex', gap: 5, marginTop: 4 }}>
                <TotalLikes proposalId={proposalId} />
                <TotalComments proposalId={proposalId} />
              </div>
            </div>
          )}
          <div className="cursor-pointer pt-1" onClick={toggleSidebar}>
            {expand && (
              <Tooltip
                classes={{ tooltip: classes.tooltip }}
                title={t('Hide the sidebar')}
              >
                <ChevronRight fontSize="large" />
              </Tooltip>
            )}
            {!expand && (
              <Tooltip
                classes={{ tooltip: classes.tooltip }}
                title={t('Show the sidebar')}
              >
                <ChevronLeft fontSize="large" />
              </Tooltip>
            )}
          </div>
        </div>
      )}
      {expand && (
        <div className="py-2 border-bottom">
          {/* Last seen just for not readOnly mode */}
          {!readOnly && !withoutHead && (
            <div className="d-flex align-items-center mb-1">
              <h5 className={`mb-0 ${classes.textModeColors}`}>
                {t('Last seen')}:
              </h5>
              <div className="sm pl-1">{lastSeen}</div>
            </div>
          )}

          {/* Proposal name */}
          <div className="d-flex align-items-center mb-1 enable-copy">
            <h5 className={`mb-0 ${classes.textModeColors}`}>
              {t('Pseudonym')}:
            </h5>
            <div
              className="sm pl-1 text-uppercase"
              style={{ wordBreak: 'break-word', hyphens: 'auto' }}
            >
              {/* {proposal.name.replace(/[0-9]/g, '')} */}
              {proposal.name}
            </div>
          </div>

          {/* Proposal class */}
          {!readOnly && (
            <div className="d-flex align-items-center enable-copy">
              <h5 className={`mb-0 ${classes.textModeColors}`}>
                {t('Class')}:
              </h5>
              {proposalClass && (
                <div
                  className="small-class-container"
                  style={{ backgroundColor: proposalClass.colour }}
                >
                  {proposalClass.class}
                </div>
              )}
              {!proposalClass && profile.role !== 'judge' && !readOnly && (
                <div className={`sm pl-1 ${classes.textModeColors}`}>
                  {t('Add class')}
                </div>
              )}
              {!proposalClass && profile.role === 'judge' && !readOnly && (
                <div className="sm pl-1 text-gray">
                  {t('The secretary will add class')}
                </div>
              )}
            </div>
          )}
          {/* Manage Proposal class */}
          {profile.role !== 'judge' && !readOnly && (
            <div className="d-flex justify-content-end mt-1">
              <Button
                style={{ padding: '5px' }}
                onClick={() => setClassModal(true)}
                size="sm"
              >
                <span
                  className={`d-flex align-items-center ${classes.textModeColors}`}
                >
                  <span className="mr-1">{t('Add and manage classes')} </span>
                  <SettingsIcon fontSize="small" />
                </span>
              </Button>
            </div>
          )}
        </div>
      )}

      {!readOnly && expand && (
        <ElementTabs
          proposal={proposal}
          proposalId={proposalId}
          openRatingModal={openRatingModal}
          openAllRatingsModal={openAllRatingsModal}
        />
      )}

      {!readOnly && (
        <ManageClasses
          proposal={proposal}
          proposalId={proposalId}
          proposalClass={proposalClass}
          close={() => setClassModal(false)}
          isOpen={classModal}
        />
      )}
    </div>
  )
}

export default withStyles(styles)(Sidebar)
