import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import _ from 'lodash'
import { Link } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'

import LgProposal from './LgProposal'
import MdProposal from './MdProposal'
import SmProposal from './SmProposal'
import XsProposalWithName from './XsProposalWithName'
import XsProposal from './XsProposal'
import ListItem from './ListItem'
import InfoTable from './InfoTable'
import VideoCard from './VideoCard'
import CommentsCards from './comments-view/CommentsCards'
import * as Helper from '../util/Helper.js'
import LoadingListView from './skeletonLoading/ListView'
import {
  filterProposals,
  sortProposals
} from '../redux/actions/proposalsActions'
import { useTranslation } from 'react-i18next'

const styles = theme => ({
  toggle: {
    width: '2rem',
    fontWeight: 700,
    fontSize: '0.875rem'
  },
  no: {
    width: '3rem',
    fontWeight: 700,
    fontSize: '0.875rem'
  },
  pseudonym: {
    width: '7rem',
    fontWeight: 700,
    fontSize: '0.875rem',
    paddingRight: 8
  },
  class: {
    width: '7.5rem',
    fontWeight: 700,
    fontSize: '0.875rem'
  },
  textModeColor: {
    color: theme.modeColors.textColor
  }
})

function ListItems({
  Data,
  proposalsOrdered,
  PublicClasses,
  profile,
  expandAll,
  users,
  classes
}) {
  const [loading, setLoading] = React.useState(true)
  const { t } = useTranslation('proposals')

  if (loading) {
    setTimeout(() => setLoading(false), 500)
  }

  const getListItems = () => {
    return _.map(Data, (value, key) => {
      const proposalClass = Helper.getProposalClass(
        key,
        proposalsOrdered,
        PublicClasses
      )
      const proposalPublicTags = Helper.getProposalPublicTags(
        key,
        proposalsOrdered
      )
      const proposalPublicNote = Helper.getProposalPublicNote(
        proposalsOrdered,
        key
      )
      const proposalPrivateNote = Helper.getProposalPrivateNote(profile, key)
      const proposalPrivateTags = Helper.getProposalPrivateTags(profile, key)
      const proposalPublicRate = Helper.getProposalPublicRate(
        proposalsOrdered,
        key
      )
      const data = {
        key,
        id: key,
        proposal: value,
        proposalClass,
        proposalPublicTags,
        proposalPrivateTags,
        proposalPublicNote,
        proposalPrivateNote,
        expandAll,
        proposalPublicRate,
        proposalsOrdered,
        PublicClasses,
        users
      }

      return <ListItem {...data} />
    })
  }

  return (
    <Grid item xs={12}>
      <div className="d-flex border-bottom ">
        <div className={classes.toggle}></div>
        <div className={classes.no}>{t('No.')}</div>
        <div className={classes.pseudonym}>{t('Pseudonym')}</div>
        <div className={classes.class}>{t('Class')}</div>
        <div className="col text-bold sm">{t('Public tags')}</div>
        <div className="col text-bold sm">{t('Public notes')}</div>
        <div className="col text-bold sm">{t('Private tags')}</div>
        <div className="col text-bold sm">{t('Private notes')}</div>
      </div>

      {loading ? <LoadingListView /> : getListItems()}
    </Grid>
  )
}

class ProposalCards extends Component {
  state = {
    loading: false
  }

  componentDidMount() {
    const { history } = this.props
    if (history.location.search || history.action === 'PUSH') {
      this.props.filterProposals(history.location.search)
    }
  }

  getCardSize = (size, value, key) => {
    const readOnly = process.env.REACT_APP_READ_ONLY
    const {
      proposalsOrdered,
      proposalsData,
      PublicClasses,
      profile,
      videoMode
    } = this.props
    const proposalClass = Helper.getProposalClass(
      key,
      proposalsOrdered,
      PublicClasses
    )
    const proposalPublicTags = Helper.getProposalPublicTags(
      key,
      proposalsOrdered
    )
    const proposalHasNote = Helper.isProposalHasNote(
      key,
      profile,
      proposalsData
    )
    const lastSeen = _.get(profile, `proposals.${key}.lastSeen`, null)
    const data = {
      seen: readOnly ? true : lastSeen,
      key,
      proposal: value,
      proposalClass,
      proposalPublicTags,
      proposalHasNote,
      size,
      isVideoTab: videoMode
    }

    if (videoMode) {
      return <VideoCard {...data} />
    }

    switch (size) {
      case 6:
        return <LgProposal {...data} />
      case 5:
        return <LgProposal {...data} />
      case 4:
        return <LgProposal {...data} />
      case 3:
        return <MdProposal {...data} />
      case 2:
        return <SmProposal {...data} />
      case 1:
        return <XsProposalWithName {...data} />
      case 0:
        return <XsProposal {...data} />
      default:
        return <MdProposal {...data} />
    }
  }

  render() {
    const {
      zoomLevel,
      Data,
      listMode,
      tableMode,
      videoMode,
      commentsMode,
      classes,
      proposalsOrdered,
      PublicClasses,
      profile,
      users,
      expandAll
    } = this.props

    return (
      <Grid
        container
        justifyContent="flex-start"
        spacing={zoomLevel === 2 ? 1 : zoomLevel <= 1 ? 2 : zoomLevel}
        className={classes.textModeColor}
      >
        {!listMode && !tableMode && !videoMode && !commentsMode && Data
          ? _.map(Data, (value, key) => {
              return (
                <Grid key={key} item>
                  <Link className="normal-text" to={`/proposal/${key}`}>
                    {this.getCardSize(zoomLevel, value, key)}
                  </Link>
                </Grid>
              )
            })
          : null}

        {listMode && Data && (
          <ListItems
            Data={Data}
            proposalsOrdered={proposalsOrdered}
            PublicClasses={PublicClasses}
            profile={profile}
            expandAll={expandAll}
            users={users}
            classes={classes}
          />
        )}

        {tableMode && Data && (
          <Grid item xs={12}>
            <InfoTable
              Data={Data}
              proposalsOrdered={proposalsOrdered}
              PublicClasses={PublicClasses}
              users={users}
            />
          </Grid>
        )}

        {commentsMode ? <CommentsCards /> : null}

        {videoMode &&
          Data &&
          _.map(Data, (value, key) => {
            return (
              <Grid key={key} item>
                {this.getCardSize(zoomLevel, value, key)}
              </Grid>
            )
          })}
      </Grid>
    )
  }
}

const mapStateToProps = state => ({
  users: state.firestore.ordered.users,
  profile: state.firebase.profile,
  PublicClasses: state.firestore.ordered.PublicClasses,
  proposalsOrdered: state.firestore.ordered.proposals,
  proposalsData: state.firestore.data.proposals,
  likes: state.firestore.data.likes
})

export default compose(
  firestoreConnect([
    { collection: 'PublicClasses', orderBy: ['position', 'asc'] },
    { collection: 'proposals' },
    { collection: 'likes' },
    { collection: 'comments' }
  ]),
  connect(mapStateToProps, { filterProposals, sortProposals })
)(withStyles(styles)(ProposalCards))
