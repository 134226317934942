import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';

import TextField from '../inputs/Text';
import CustomRadio from '../inputs/CustomRadio';
import Button from '../inputs/Button';

const useStyles = makeStyles(theme => ({
  dialogPaper: {
    position: 'relative',
    borderRadius: 0,
    width: 432,
    minHeight: 610,
    backgroundColor: theme.modeColors.inputBackground,
  },
}));

const EditClass = ({
  proposalClass,
  close,
  isOpen,
  handleChange,
  chosenColor,
  saveEditClass,
}) => {
  const classes = useStyles();
  const proposalClassColors = [
    '#999da0',
    '#61be65',
    '#f3e200',
    '#ffa632',
    '#ff4c4c',
    '#3f51b5',
    '#61a8be',
    '#bc61be',
    '#dedede',
    '#a74c32',
    '#b6a55c',
    '#ffa8ad',
  ];

  return (
    <Dialog
      onClose={close}
      aria-labelledby="customized-dialog-title"
      open={isOpen}
      maxWidth="md"
      classes={{ paper: classes.dialogPaper }}
    >
      <div className="px-3 py-3">
        <div className="border-bottom pb-2 mb-3">
          <h2>Edit class</h2>
          <div className="d-flex align-items-center justify-content-start">
            <h3 className="mr-2 mb-0">Class: </h3>
            <div
              className="big-class-container "
              style={{ backgroundColor: proposalClass.colour }}
            >
              {proposalClass.class}
            </div>
          </div>
        </div>

        <div>
          <h5 className="mb-2">Edit class name</h5>

          <div className="mb-2">
            <TextField
              defaultValue={proposalClass.class}
              onChange={handleChange}
              name="editNameOfClass"
              placeholder={proposalClass.class}
              size="md"
            />
          </div>

          <div className="mb-4">
            <div className="sm mb-1">Choose colour</div>
            {proposalClassColors.map(color => (
              <CustomRadio
                checked={color === chosenColor}
                onChange={handleChange}
                key={color}
                name="editChosenColor"
                color={color}
                value={color}
              />
            ))}
          </div>

          <div className="d-flex align-items-center justify-content-end mt-2">
            <Button
              style={{ padding: '9px 12px 7px', marginRight: '10px' }}
              size="md"
              variant="outlined"
              color="primary"
              onClick={close}
            >
              Cancel
            </Button>
            <Button
              style={{ padding: '9px 12px 7px' }}
              size="md"
              variant="contained"
              color="primary"
              onClick={saveEditClass}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default EditClass;
