import React from 'react'
import styled from 'styled-components'
import { Grid, IconButton } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { v4 as uuidv4 } from 'uuid'

import Input from '../competition-info/submission/components/Input'
import useFirestore from '../competition-info/submission/hooks/useFirestore'
import useCollection from '../competition-info/submission/hooks/useCollection'
import ToggleSwitch from '../competition-info/submission/components/ToggleSwitch'
import DeleteIcon from '@material-ui/icons/Delete'

export default function TabsWithIframeLinks() {
  const { documents } = useCollection('TabsWithIframeLinks')
  const { addDocument, updateDocument, deleteDocument } = useFirestore(
    'TabsWithIframeLinks'
  )
  // const [isIframeLink, setIsIframeLink] = React.useState(true)
  const [tabs, setTabs] = React.useState([])
  const { t } = useTranslation('proposals')

  const handleAddTab = async () => {
    const tabId = uuidv4()
    addDocument(tabId, {
      id: tabId,
      label: '',
      iframeLink: '',
      availableAt: '',
      value: '',
      pageHeight: 1000,
      isIframeLink: true
    })
    setTabs([
      ...tabs,
      {
        id: tabId,
        label: '',
        iframeLink: '',
        availableAt: '',
        value: '',
        pageHeight: 1000,
        isIframeLink: true
      }
    ])
  }

  React.useEffect(() => {
    if (!!documents && Object.values(documents).length > 0) {
      setTabs(Object.values(documents))
    } else {
      setTabs([])
    }
  }, [documents])

  if (!documents || Object.values(documents).length === 0) {
    return (
      <div style={{ textAlign: 'center' }}>
        <StyledButton onClick={handleAddTab}>{t('Add row')}</StyledButton>
      </div>
    )
  }

  const toggleIframeLink = id => {
    const updatedTabs = tabs.find(tab => tab.id === id)
    updateDocument(id, { isIframeLink: !updatedTabs.isIframeLink })
  }

  return (
    <div>
      <Grid container className="py-1" spacing={5}>
        <Grid item xs={2} className="h5 mb-0" style={{ paddingBottom: 0 }}>
          {t('Unique name')}
        </Grid>
        <Grid item xs={1} className="h5 mb-0" style={{ paddingBottom: 0 }}>
          {t('Link / Code')}
        </Grid>
        <Grid item xs={4} className="sm" style={{ paddingBottom: 0 }}>
          {t('Iframe link')}
        </Grid>
        <Grid item xs={2} className="sm" style={{ paddingBottom: 0 }}>
          {t('Available at page')} (settings / info)
        </Grid>
        <Grid item xs={2} className="sm" style={{ paddingBottom: 0 }}>
          {t('Height')}
        </Grid>
        <Grid item xs={1} className="sm" style={{ paddingBottom: 0 }}>
          {t('Remove')}
        </Grid>
      </Grid>

      <Grid container className="py-1" spacing={5}>
        {tabs.map(
          ({
            id,
            label,
            iframeLink,
            availableAt,
            pageHeight,
            isIframeLink,
            html
          }) => (
            <React.Fragment key={id}>
              <Grid item xs={2} className="h5 mb-0" style={{ paddingTop: 10 }}>
                <Input
                  inputType="text"
                  label=""
                  inputId={`tabName-${id}`}
                  inputName="tab-name"
                  handleSave={value =>
                    updateDocument(id, {
                      label: value,
                      value: value
                        .toLowerCase()
                        .split(' ')
                        .join('-')
                    })
                  }
                  inputValue={label}
                />
              </Grid>
              <Grid
                item
                xs={1}
                className="h5 mb-0"
                style={{
                  paddingBottom: 0,
                  display: 'flex',
                  justifyContent: 'center'
                }}
              >
                <ToggleSwitch
                  itemId={id}
                  checked={isIframeLink}
                  onChange={() => toggleIframeLink(id)}
                />
              </Grid>
              <Grid item xs={4} className="sm" style={{ paddingTop: 10 }}>
                {isIframeLink ? (
                  <Input
                    inputType="text"
                    label=""
                    inputId={`iframeLink-${id}`}
                    inputName="iframe-link"
                    handleSave={value =>
                      updateDocument(id, { iframeLink: value })
                    }
                    inputValue={iframeLink}
                  />
                ) : (
                  <textarea
                    rows="10"
                    style={{ width: '100%' }}
                    onChange={event =>
                      updateDocument(id, { html: event.target.value })
                    }
                    value={html}
                  ></textarea>
                )}
              </Grid>
              <Grid item xs={2} className="sm" style={{ paddingTop: 10 }}>
                <Input
                  inputType="text"
                  label=""
                  inputId={`availableAt-${id}`}
                  inputName="available-at"
                  handleSave={value =>
                    updateDocument(id, { availableAt: value })
                  }
                  inputValue={availableAt}
                />
              </Grid>
              <Grid item xs={2} className="sm" style={{ paddingTop: 10 }}>
                <Input
                  inputType="number"
                  label=""
                  inputId={`pageHeight-${id}`}
                  inputName="page-height"
                  handleSave={value =>
                    updateDocument(id, { pageHeight: parseInt(value) })
                  }
                  inputValue={pageHeight}
                />
              </Grid>
              <Grid
                item
                xs={1}
                className="sm"
                style={{
                  paddingTop: 10
                }}
              >
                <IconButton
                  onClick={() => deleteDocument(id)}
                  size="small"
                  aria-label="Delete"
                >
                  <DeleteIcon fontSize="medium" />
                </IconButton>
              </Grid>
            </React.Fragment>
          )
        )}
      </Grid>

      <div style={{ textAlign: 'center' }}>
        <StyledButton onClick={handleAddTab}>{t('Add row')}</StyledButton>
      </div>
    </div>
  )
}

const StyledButton = styled.button`
  border: 1px solid #000;
  border-radius: 4px;
  background-color: #fff;
  margin-top: 30px;
  padding: 8px 16px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: #000;
    color: #fff;
  }
`
