import React from 'react'
import { MdArchitecture, MdInsertChart, MdNaturePeople } from 'react-icons/md'
import { HiOutlineLightBulb } from 'react-icons/hi'
import { FaRoad } from 'react-icons/fa'
import { BiIntersect } from 'react-icons/bi'
import { GiThreeLeaves } from 'react-icons/gi'

export const rating_fields = [
  {
    name: 'overall',
    label: 'Total',
    svg: <MdInsertChart />
  },
  {
    name: 'kokonaisvisio',
    label: 'Kokonaisvisio',
    svg: <HiOutlineLightBulb />
  },
  {
    name: 'kaupunkielämä',
    label: 'Kaupunkielämä',
    svg: <MdNaturePeople />
  },
  {
    name: 'kaupunkikuva_ja_arkkitehtuuri',
    label: 'Kaupunkikuva ja arkkitehtuuri',
    svg: <MdArchitecture />
  },
  {
    name: 'liikenne',
    label: 'Liikenne',
    svg: <FaRoad />
  },
  {
    name: 'kestävyys',
    label: 'Kestävyys',
    svg: <GiThreeLeaves />
  }
]
