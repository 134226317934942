export const updateDataBase = (coll, doc, data) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    return firestore
      .collection(coll)
      .doc(doc)
      .update({ ...data });
  };
};

export const addToDataBase = (coll, doc, data) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    return firestore
      .collection(coll)
      .doc(doc)
      .set({ ...data }, { merge: true });
  };
};

export const deleteFromDataBase = (coll, doc) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    return firestore
      .collection(coll)
      .doc(doc)
      .delete();
  };
};
