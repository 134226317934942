import React from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Button from '../inputs/Button';

const RemoveClass = ({ open, handleClose, removeClass, className }) => (
  <Dialog
    open={open}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">Remove class.</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        That's will remove {className} from all proposals.
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button size="md" variant="outlined" color="primary" onClick={() => handleClose()}>
        Cancel
      </Button>
      <Button
        size="md"
        variant="contained"
        color="primary"
        onClick={() => removeClass()}
        autoFocus
      >
        remove
      </Button>
    </DialogActions>
  </Dialog>
);

export default RemoveClass;
