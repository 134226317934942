import React from 'react';
import Chip from '@material-ui/core/Chip';
import Button from '../inputs/Button';
import Dialog from '@material-ui/core/Dialog';
import TextField from '../inputs/Text';

const NewTag = ({
  isOpen,
  closeModal,
  tag,
  handleChange,
  saveSearchAsTag,
  searchObject,
  classes,
}) => (
  <Dialog
    onClose={closeModal}
    aria-labelledby="customized-dialog-title"
    open={isOpen}
    maxWidth="md"
    classes={{ paper: classes.dialogPaper }}
  >
    <div className="p-3">
      <h2>Add new private tag for this search</h2>
      {searchObject.classes &&
        searchObject.classes.split(',').map((val, i) => (
          <Chip
            key={i}
            label={val}
            classes={{
              root: classes.chipTag,
              label: 'xs p-0',
            }}
          />
        ))}
      {searchObject.public_tags &&
        searchObject.public_tags.split(',').map((val, i) => (
          <Chip
            key={i}
            label={val}
            classes={{
              root: classes.chipTag,
              label: 'xs p-0',
            }}
          />
        ))}
      {searchObject.private_tags &&
        searchObject.private_tags.split(',').map((val, i) => (
          <Chip
            key={i}
            label={val}
            classes={{
              root: classes.chipTag,
              label: 'xs p-0',
            }}
          />
        ))}
      <div className="d-flex align-items-center mt-3 pb-2 border-bottom">
        <TextField
          value={tag}
          onChange={handleChange}
          className="mb-1"
          id="email"
          placeholder="Text"
          size="md"
        />
        <Button
          style={{ marginLeft: '16px', marginBottom: '3px', padding: '7px 12px 5px' }}
          size="md"
          variant="outlined"
          color="primary"
          onClick={() => saveSearchAsTag()}
        >
          Add
        </Button>
      </div>

      <div className="d-flex align-items-center justify-content-end pt-2">
        <Button
          style={{ marginLeft: '16px' }}
          size="md"
          variant="contained"
          onClick={closeModal}
          color="primary"
        >
          Done
        </Button>
      </div>
    </div>
  </Dialog>
);

export default NewTag;
