import React from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import Checkbox from '@material-ui/core/Checkbox'
import { withStyles } from '@material-ui/core/styles'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import Text from './Text'
import Button from './Button'
import { useTheme } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'

const styles = theme => ({
  listHeader: {
    outline: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0px 8px',
    backgroundColor: theme.modeColors.inputBackground
  },
  listFooter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '8px 16px 8px 8px',
    backgroundColor: theme.modeColors.inputBackground,
    borderTop: '1px solid #e0e0e0',
    width: '100%'
  },
  menuItem: {
    margin: '4px 8px',
    padding: '0px 8px',
    minHeight: 30,
    backgroundColor: theme.modeColors.inputBackground
  },
  checkbox: {
    padding: 0,
    paddingRight: 8
  },
  filterBtn: {
    border: '1px solid #3f51b5',
    borderRadius: 0,
    padding: '4px 7px 3px',
    color: '#3f51b5',
    backgroundColor: '#fff'
  },
  filterBtnActive: {
    color: '#fff',
    backgroundColor: '#3f51b5',
    '&:focus': {
      borderRadius: 0,
      outline: 'none'
    }
  }
})

const MultipleSelect = ({
  placeholder,
  options,
  values,
  onChange,
  selectAll,
  clearAll,
  apply,
  classes,
  publicTagFilterBy,
  setPublicTagFilterBy
}) => {
  const [open, setOpen] = React.useState(false)
  const { t } = useTranslation('navbar')

  const theme = useTheme()

  const MenuProps = {
    MenuListProps: {
      style: {
        position: 'relative',
        overflow: 'auto',
        paddingBottom: 50,
        backgroundColor: theme.modeColors.inputBackground,
        minHeight: '500px'
      }
    }
  }

  const applyChange = () => {
    apply()
    handleClose()
  }

  function handleClose() {
    setOpen(false)
  }

  function handleOpen() {
    setOpen(true)
  }

  return (
    <Select
      multiple
      displayEmpty
      open={open}
      onClose={handleClose}
      onOpen={handleOpen}
      value={values}
      onChange={onChange}
      MenuProps={
        (MenuProps,
        {
          disablePortal: true,
          disableAutoFocus: true,
          disableEnforceFocus: true
        })
      }
      input={
        <Text active={values.length} id="select-multiple-checkbox" size="md" />
      }
      renderValue={selected => {
        if (selected.length === 0) {
          return <span>{placeholder}</span>
        }

        return selected.join(', ')
      }}
    >
      <MenuItem
        in={open ? 'true' : 'false'}
        value="none"
        className={classes.listHeader}
      >
        <Button onClick={() => selectAll()} size="md" color="primary">
          {t('Select all')}
        </Button>
        <Button onClick={() => clearAll()} size="md">
          {t('Clear all')}
        </Button>
      </MenuItem>
      {/* <Divider /> */}
      {options.map((option, i) => (
        <MenuItem
          disableGutters={true}
          key={i}
          value={option.name}
          classes={{
            root: classes.menuItem
          }}
        >
          <Checkbox
            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
            checkedIcon={<CheckBoxIcon fontSize="small" />}
            checked={values.indexOf(option.name) > -1}
            classes={{
              root: classes.checkbox
            }}
            color="default"
          />

          <div className="d-flex full-width align-items-center">
            <div className="sm">{option.name} </div>
            <div className="d-flex ml-auto align-items-center">
              <div className="sm">{option.amount} </div>
              {option.color && (
                <div
                  style={{
                    height: '16px',
                    width: '16px',
                    backgroundColor: option.color,
                    marginLeft: '8px'
                  }}
                />
              )}
            </div>
          </div>
        </MenuItem>
      ))}
      <MenuItem value="none" className={classes.listFooter}>
        {placeholder === t('Jury tags') ||
        placeholder === t('My private tags') ? (
          <div
            className="sm pl-1 m-0"
            style={{
              display: 'flex',
              alignItems: 'center',
              height: '30px'
            }}
          >
            <p>{t('Matching')}</p>
            <button
              className={`${classes.filterBtn} ${
                publicTagFilterBy === 'any' ? classes.filterBtnActive : null
              }`}
              onMouseEnter={() => setPublicTagFilterBy('any')}
              onMouseLeave={() => setPublicTagFilterBy(null)}
              onClick={() => {
                applyChange()
                setPublicTagFilterBy(null)
              }}
              style={{
                marginLeft: '8px',
                marginRight: '8px',
                cursor: 'pointer'
              }}
            >
              {t('Any')}
            </button>
            <button
              className={`${classes.filterBtn} ${
                publicTagFilterBy === 'every' ? classes.filterBtnActive : null
              }`}
              onMouseEnter={() => setPublicTagFilterBy('every')}
              onMouseLeave={() => setPublicTagFilterBy(null)}
              onClick={() => {
                applyChange()
                setPublicTagFilterBy(null)
              }}
              style={{
                marginLeft: '4px',
                marginRight: '8px',
                cursor: 'pointer'
              }}
            >
              {t('Every')}
            </button>
            <p>{` ${t('of')} ${values.length} ${t('criteria')}`}</p>
          </div>
        ) : (
          <p className="sm pl-1 m-0">
            {t('Selected')}: {values.length}
          </p>
        )}
        {placeholder === t('Jury tags') ||
        placeholder === t('My private tags') ? null : (
          <Button
            variant="outlined"
            color="primary"
            onClick={applyChange}
            size="sm"
          >
            {t('Apply')}
          </Button>
        )}
      </MenuItem>
    </Select>
  )
}

export default withStyles(styles)(MultipleSelect)
