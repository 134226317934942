import React from 'react';
import PropType from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Create';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles({
  tooltip: {
    fontSize: '0.875rem',
    padding: '8px 12px',
  },
  container: {
    width: '100%',
  },
  deleteIcon: {
    right: '0.5rem',
  },
});

const SortItem = ({
  applyClassToProposal,
  proposalClass,
  handleOpenRemove,
  openEditDialog,
}) => {
  const classes = useStyles();

  return (
    <ListItem
      onClick={applyClassToProposal}
      key={proposalClass.id}
      className="d-flex align-items-center justify-content-between pl-1"
      button
      classes={{ container: classes.container }}
    >
      <div
        className="big-class-container"
        style={{ backgroundColor: proposalClass.colour }}
      >
        {proposalClass.class}
      </div>
      <ListItemSecondaryAction classes={{ root: classes.deleteIcon }}>
        <Tooltip classes={{ tooltip: classes.tooltip }} title="Edit name and color">
          <IconButton onClick={openEditDialog} size="small" aria-label="Edit">
            <EditIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        <Tooltip
          classes={{ tooltip: classes.tooltip }}
          title="Remove the class from every where"
        >
          <IconButton onClick={handleOpenRemove} size="small" aria-label="Delete">
            <DeleteIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

SortItem.propTypes = {
  applyClassToProposal: PropType.func.isRequired,
  handleOpenRemove: PropType.func.isRequired,
  proposalClass: PropType.object.isRequired,
};

export default SortItem;
