import React from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import useLocalStorage from '../competition-info/submission/hooks/useLocalStorage'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 60
  },
  selectEmpty: {
    height: 20
  }
}))

const SelectPhase = ({ selectedPhase, changedPhase }) => {
  const { t } = useTranslation('proposals')
  const classes = useStyles()
  const [phase, setPhase] = useLocalStorage('jt-selected-phase', selectedPhase)
  // const isPhase2 = process.env.REACT_APP_API_PHASE_2 ? true : false;

  const handleChangePhase = event => {
    setPhase(event.target.value)
    changedPhase(event.target.value)
  }

  return (
    <FormControl className={classes.formControl}>
      <Select
        value={phase}
        onChange={handleChangePhase}
        displayEmpty
        className={classes.selectEmpty}
      >
        <MenuItem value="" disabled>
          {t('Select phase')}
        </MenuItem>
        <MenuItem value="qualification">{t('Qualifications')}</MenuItem>
        <MenuItem value="phase-1">{t('First phase')}</MenuItem>
        <MenuItem value="phase-2">{t('Second phase')}</MenuItem>
      </Select>
    </FormControl>
  )
}

SelectPhase.propTypes = {
  selectedPhase: PropTypes.string.isRequired,
  changedPhase: PropTypes.func.isRequired
}

export default SelectPhase
