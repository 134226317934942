import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form';
import { firestoreConnect } from 'react-redux-firebase';
import { withStyles } from '@material-ui/core/styles';
import { required, email } from 'redux-form-validators';
import {
  createUserWithEmailAndPassword,
  sendSignInLinkToEmail,
} from '../redux/actions/authActions';
import { updateDataBase, addToDataBase } from '../redux/actions/dataBase';
import Button from '../inputs/Button';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import TextField from '../inputs/Text';
import Select from '../inputs/Select';
import { withSnackbar } from 'notistack';

const styles = theme => ({
  dialogPaper: {
    borderRadius: 0,
    backgroundColor: theme.modeColors.inputBackground,
    width: '500px',
  },
  dialogTitle: {
    borderBottom: '1px solid #e0e2e4',
    margin: 0,
    padding: '20px 16px 18px',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: '#353c43',
  },
});

const options = [
  {
    label: 'Judge',
    value: 'judge',
  },
  {
    label: 'Secretary',
    value: 'secretary',
  },
];

class ManageUser extends React.Component {
  state = {
    role: 'judge',
  };

  componentDidMount() {
    if (this.props.userData) {
      this.props.initialize(this.props.userData);
      this.setState({ role: this.props.userData.role });
    }
  }

  handleChangeRole = event => {
    this.setState({ role: event.target.value });
  };

  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };

  handleInvivation = values => {
    const { userData } = this.props;
    const demoPassword = Math.random()
      .toString(36)
      .substr(2, 9);
    const role = this.state.role;
    const initials = `${values.firstName}.${values.lastName}`;
    if (userData) {
      const data = { ...values, initials, role };
      this.props.updateDataBase('users', userData.id, data).then(() => {
        this.closeModal();
      });
    } else {
      const data = { ...values, initials, role, demoPassword, newUser: true };
      this.props
        .createUserWithEmailAndPassword(values.email, demoPassword)
        .then(resp => {
          // add the user details to Firestore
          this.props.enqueueSnackbar(`Account created for ${values.email}`, {
            variant: 'success',
          });

          this.props
            .addToDataBase('users', resp.user.uid, data)
            .then(() => {
              // Everything ok start send invitation to the email
              this.props.enqueueSnackbar('User data saved', { variant: 'success' });

              this.props
                .sendSignInLinkToEmail(values.email)
                .then(() => {
                  // invitation sent
                  this.props.enqueueSnackbar('Invitation sent', { variant: 'success' });
                })
                .catch(err => {
                  //didn't sent the invitation
                  this.props.enqueueSnackbar(err.message, { variant: 'warning' });
                });
            })
            .catch(err => {
              //Error during save data on Firestore
              this.props.enqueueSnackbar(err.message, { variant: 'warning' });
            });
        })
        .catch(err => {
          //acount already avalibale update only the details
          this.props.enqueueSnackbar(err.message, { variant: 'warning' });
          this.props.deletedUsers.map(user => {
            if (user.email === values.email) {
              this.props
                .addToDataBase('users', user.id, data)
                .then(() => {
                  // Everything ok start send invitation to the email
                  this.props.enqueueSnackbar('User data saved', { variant: 'success' });

                  this.props
                    .sendSignInLinkToEmail(values.email)
                    .then(() => {
                      // invitation sent
                      this.props.enqueueSnackbar('Invitation sent', {
                        variant: 'success',
                      });
                    })
                    .catch(err => {
                      //didn't sent the invitation
                      this.props.enqueueSnackbar(err.message, { variant: 'warning' });
                    });
                })
                .catch(err => {
                  //Error during save data on Firestore
                  this.props.enqueueSnackbar(err.message, { variant: 'warning' });
                });
            }
            return null;
          });
        });
      this.closeModal();
    }
  };

  closeModal = () => {
    this.props.close();
    this.props.clearFields();
  };

  render() {
    const { isOpen, classes, userData } = this.props;
    return (
      <Dialog
        aria-labelledby="customized-dialog-title"
        open={isOpen}
        maxWidth="sm"
        classes={{ paper: classes.dialogPaper }}
      >
        <DialogTitle disableTypography className={classes.dialogTitle}>
          <h3 className="mb-0">{userData ? 'Edit user' : 'Invite new user'}</h3>
          <IconButton
            aria-label="Close"
            className={classes.closeButton}
            onClick={this.closeModal}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </DialogTitle>
        <form onSubmit={this.props.handleSubmit(this.handleInvivation)}>
          <div className="px-3 pt-2 pb-0">
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={12} md={4}>
                <div className="sm text-md-right">Name</div>
              </Grid>
              <Grid item xs={12} md={6}>
                <Field
                  component={TextField}
                  id="firstname"
                  name="firstName"
                  onChange={this.handleChange}
                  size="md"
                  placeholder="Input"
                  validate={[required()]}
                  validation
                />
              </Grid>
            </Grid>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={12} md={4}>
                <div className="sm text-md-right">Surname</div>
              </Grid>
              <Grid item xs={12} md={6}>
                <Field
                  component={TextField}
                  id="lastName"
                  name="lastName"
                  onChange={this.handleChange}
                  size="md"
                  placeholder="Input"
                  validate={[required()]}
                  validation
                />
              </Grid>
            </Grid>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={12} md={4}>
                <div className="sm text-md-right">Email</div>
              </Grid>
              <Grid item xs={12} md={6}>
                <Field
                  component={TextField}
                  disabled={!!userData}
                  id="email"
                  name="email"
                  onChange={this.handleChange}
                  size="md"
                  placeholder="Input"
                  validate={[email()]}
                  validation
                />
              </Grid>
            </Grid>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={12} md={4}>
                <div className="sm text-md-right">Profession</div>
              </Grid>
              <Grid item xs={12} md={6}>
                <Field
                  component={TextField}
                  id="proffesion"
                  name="proffesion"
                  onChange={this.handleChange}
                  size="md"
                  placeholder="Input"
                  validate={[required()]}
                  validation
                />
              </Grid>
            </Grid>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={12} md={4}>
                <div className="sm text-md-right">Role</div>
              </Grid>
              <Grid item xs={12} md={6}>
                <Select
                  onChange={this.handleChangeRole}
                  value={this.state.role}
                  options={options}
                />
              </Grid>
            </Grid>
          </div>
          <div className="d-flex align-items-center justify-content-end pt-1 m-3">
            <Button
              size="md"
              variant="outlined"
              onClick={this.closeModal}
              color="primary"
            >
              Cancel
            </Button>
            <Button
              style={{ marginLeft: '8px' }}
              type="submit"
              size="md"
              variant="contained"
              color="primary"
            >
              {userData ? 'Update' : 'Send invitation'}
            </Button>
          </div>
        </form>
      </Dialog>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateDataBase: (coll, doc, data) => dispatch(updateDataBase(coll, doc, data)),
    addToDataBase: (coll, doc, data) => dispatch(addToDataBase(coll, doc, data)),
    createUserWithEmailAndPassword: (email, pass) =>
      dispatch(createUserWithEmailAndPassword(email, pass)),
    sendSignInLinkToEmail: email => dispatch(sendSignInLinkToEmail(email)),
  };
};

const mapStateToProps = state => {
  return {
    deletedUsers: state.firestore.ordered.deletedUsers,
  };
};

const ManageUserForm = reduxForm({
  form: 'ManageUserForm', // a unique identifier for this form
})(withStyles(styles)(ManageUser));

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect([{ collection: 'deletedUsers' }])
)(withSnackbar(withStyles(styles)(ManageUserForm)));
