import React from 'react'

import { projectFirestore } from '../../../util/fbconfig'

export default function useCollection(
  collection,
  queryKey1 = '',
  queryValue1 = '',
  queryKey2 = '',
  queryValue2 = ''
) {
  const [documents, setDocuments] = React.useState(null)
  const [error, setError] = React.useState(null)

  React.useEffect(() => {
    let ref =
      !!queryKey1 && queryValue1 && !!queryKey2 && queryValue2
        ? projectFirestore
            .collection(collection)
            .where(queryKey1, '==', queryValue1)
            .where(queryKey2, '==', queryValue2)
        : !!queryKey1 && queryValue1 && !queryKey2 && !queryValue2
        ? projectFirestore
            .collection(collection)
            .where(queryKey1, '==', queryValue1)
        : projectFirestore.collection(collection)

    const unsubscribe = ref.onSnapshot(
      snapshot => {
        if (snapshot.empty) {
          setError('No data')
          // setIsLoading(false)
        } else {
          let results = {}
          snapshot.docs.forEach(doc => {
            return (results[doc.id] = doc.data())
          })
          setDocuments(results)
          // setIsLoading(false)
        }
      },
      error => {
        setError(error)
        // setIsLoading(false)
      }
    )

    return () => unsubscribe()
  }, [collection, queryKey1, queryKey2, queryValue1, queryValue2])

  return { documents, error }
}
