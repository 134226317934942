import React from 'react';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import { isLoaded, isEmpty } from 'react-redux-firebase';
// import Spinner from '../spinner/Spinner';

const PublicComponent = ({ location, component: C, auth }) => {
  const { from } = location.state || { from: { pathname: '/' } };
  if (!isLoaded(auth)) {
    return <div />;
  } else if (isEmpty(auth)) {
    return <C />;
  }
  return <Redirect to={from} />;
};

const mapStateToProps = state => {
  return {
    authError: state.auth.authError,
    auth: state.firebase.auth,
  };
};

export default withRouter(connect(mapStateToProps)(PublicComponent));
